import { action, makeObservable, observable } from "mobx";
import I18n from "../../../../../../../../core/localization/I18n";
import ModalContext from "../../../../../../../../core/modalZ/context/ModalContext";
import { UiActionRenderers } from "../../../../../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../../../../../core/util/BaseModel";
import ActivityProcessesApi, {
  ActivityProcessesApi as IActivityProcessesApi
} from "../../../../../../../../services/api/v2/activityProcesses/ActivityProcesses.api";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../../../../../services/api/v2/impactGroups/ImpactGroups.api";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import { SingleFormModel } from "../../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getActivityProcessImpactGroupFormFields } from "./ActivityProcessImpactGroups_formFields";

export class ActivityProcessImpactGroupsSidebarModel extends BaseModel {
  activityProcessProvider: IActivityProcessesApi;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable.ref impactGroups: FP.Entities.IImpactGroup[];
  @observable searchValue: string = "";
  organisationId: number;
  projectId: number;
  impactGroupsProvider: IImpactGroupsApi;
  projectProcessProvider: IProjectProcessesApi;
  formModel: SingleFormModel;
  projectProcessId: number;

  constructor(organisationId: number, projectId: number, projectProcessId: number) {
    super();
    makeObservable(this);
    this.projectProcessProvider = ProjectProcessesApi;
    this.impactGroupsProvider = ImpactGroupsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.activityProcessProvider = ActivityProcessesApi;
    this.isLoading = true;
    this.projectProcessId = projectProcessId;
    this.initForm();
  }

  onMount = async () => {
    await this.loadImpactGroups(this.projectProcessId);
  };

  initForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.setActions([
      {
        id: "action1",
        label: I18n.t("phrases.done"),
        onAction: ev => {
          ModalContext.hide();
        },
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ]);
  };

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.hideSearchMode();
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
  };

  @action
  setImpactGroups = (impactGroups: FP.Entities.IImpactGroup[]) => {
    this.impactGroups = impactGroups;

    this.isLoading = false;
  };

  @action
  loadImpactGroups = async (activityProcessId: number) => {
    let res = await this.projectProcessProvider.getImpactGroups(this.organisationId, this.projectId, activityProcessId);
    if (!res || res.isError) return;

    this.setImpactGroups(res.payload);
    this.setFormModel();
  };

  @action
  setFormModel = () => {
    this.formModel.formFields = getActivityProcessImpactGroupFormFields(
      this.projectProcessProvider,
      this.impactGroupsProvider,
      this.organisationId,
      this.projectId,
      this.projectProcessId,
      this.impactGroups
    );
  };
}
