import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import { ExportableProjectApi } from "../exportableApi/ExportableProjectApiModel";
import http from "../Http";

export class ImpactGroupsApi extends ExportableProjectApi<FP.Entities.IImpactGroup> {
  controller: string = "impact-groups";

  constructor(http: AxiosInstance) {
    super(http, "impact-groups");
  }

  getImpactsUnassignedToImpactGroup = async (
    organisationId: number,
    projectId: number,
    impactGroupId: number = 0,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}/impacts/unassigned`;

    let res = await this.http.get(url);
    return res.data;
  };

  async getImpactGroupStakeholders(
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholder[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}/project-stakeholders`;

    let res = await this.http.get(url);
    return res.data;
  }

  async getImpactGroupImpacts(
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}/impacts`;

    let res = await this.http.get(url);
    return res.data;
  }

  async getDetailed(
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactGroup>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${impactGroupId}`;
    let res = await this.http.get(url);
    return res.data;
  }

  addImpactsAsync = async (
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    impacts: number[],
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/impact-groups/${impactGroupId}/impacts`,
      impacts,
      config
    );
    return res.data;
  };

  getComments = async (
    organisationId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment[]>> => {
    let res = await this.http.get(this.url + impactGroupId + "/comments", config);

    return res.data;
  };

  addComment = async (
    organisationId: number,
    impactGroupId: number,
    comment: FP.Entities.IComment,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IComment>> => {
    let res = await this.http.post(this.url + impactGroupId + "/comments", comment, config);

    return res.data;
  };

  removeImpact = async (
    organisationId: number,
    projectId: number,
    impactGroupId: number,
    impactId: number,
    config?: AxiosRequestConfig
  ): Promise<boolean> => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/impact-groups/${impactGroupId}/impacts/${impactId}`,
      config
    );

    return res.data;
  };

  alreadyExists = async (
    organisationId: number,
    projectId: number,
    name: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/impact-groups/already-exists/`,
      { name: name },
      config
    );

    return res.data;
  };

  async getFilterList(
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactGroup[]>> {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/filter-list`;
    let res = await this.http.get(url, config);

    return res.data;
  }
}

const instance = new ImpactGroupsApi(http);
export default instance;
