import _ from "lodash";
import { AutocompleteOption } from "../../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL } from "../../../../../../../../constants";
import { IMultiSelectorModel } from "../../../../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../../../core/forms/helpers/FormFieldMappers";
import { filterOutExistingProcessAppVals } from "../../../../../../../../core/grids/GridHelpers";
import I18n from "../../../../../../../../core/localization/I18n";
import { ActivityProcessesApi } from "../../../../../../../../services/api/v2/activityProcesses/ActivityProcesses.api";
import { ProjectProcessesApi } from "../../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";

export const getProjectProcessAppsSidebarFields = (
  organisationId: number,
  projectId: number,
  projectProcessProvider: ProjectProcessesApi,
  processProvider: ActivityProcessesApi,
  process: FP.Entities.IProjectProcess,
  currentProcessApps: FP.Entities.IProcessApp[]
) => {
  const removeProcessApp = async (processApp: FP.Entities.IProcessApp) => {
    let res = await projectProcessProvider.removeProcessApp(organisationId, projectId, process.id, processApp.id);
    if (!res) return;
  };

  const addProcessApp = async (processApp: FP.Entities.IProcessApp) => {
    let res = await projectProcessProvider.addProcessApp(
      organisationId,
      projectId,
      process.id,
      processApp.id,
      processApp.name
    );
    if (!res) return;
  };

  const addNewProcessApp = async (processAppName: string) => {
    const processApp: FP.Entities.IProcessApp = {
      id: 0,
      name: processAppName,
      slug: _.kebabCase(processAppName),
      organisationId: organisationId
    };

    await addProcessApp(processApp);
  };

  const processApps: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "processApps",
    placeholder: I18n.t("placeholders.searchOrCreateProcessApps"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: any) => e.text} />,
    onItemSelected: e => addProcessApp(e),
    onFocus: async function () {
      const self: IMultiSelectorModel = this;

      const currentProcessApps = await projectProcessProvider.getProcessApps(organisationId, projectId, process.id);
      const orgProcessApps = await processProvider.getAppsByOrgId(organisationId, projectId, projectId);

      if (currentProcessApps?.payload && orgProcessApps?.payload) {
        const currentVals = self.extractValue();
        const sortedProcessApps = _.orderBy(orgProcessApps.payload, [processApp => processApp.name.toLowerCase()]);
        self.setOptions(
          filterOutExistingProcessAppVals(currentVals, sortedProcessApps).map(e => ({ ...e, text: e.name }))
        );
      }
    },

    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: currentProcessApps.map(e => {
      return { ...e, text: e.name };
    }),
    isHidden: false,
    valueLabelFn: e => e?.text || e.name,
    saveOnEnterFn: e => addNewProcessApp(e.target.value),
    removeItemFn: (e, i) => removeProcessApp(e),
    isTagSelector: false,
    allowFreeText: true,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: I18n.t("forms.processAppsResultsNotFound"),
    searchResultHint: I18n.t("forms.processAppsSearchResultHint")
  };

  const fields = [];

  fields.push(processApps);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
