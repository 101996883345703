import { action, makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../../../../components/ui/Button";
import I18n from "../../../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../services/api/v2/impacts/Impacts.api";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getImpactDescriptionFormFields } from "./ImpactRichTextSidebar_fields";

export class ImpactRichTextSidebarModel extends BaseModel {
  impactsProvider: IImpactsApi;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;
  impactId: number;
  formModel: SingleFormModel;
  value: string;
  saveFN: (newValue: string) => Promise<any>;

  constructor(
    closeModalFn: () => void,
    organisationId: number,
    projectId: number,
    impactId: number,
    value: string,
    saveFN: (newValue: string) => Promise<any>
  ) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = ImpactsApi;
    this.impactId = impactId;
    this.saveFN = saveFN;
    this.formModel = new SingleFormModel();
    this.value = value;
    this.closeModalFn = closeModalFn;
  }

  onMount = async () => {
    await this.loadImpact(this.impactId);
    this.formModel.formFields = getImpactDescriptionFormFields(this.value);
    this.formModel.setActions(this.getFormActions());
  };

  getFormActions = () => [
    {
      id: "cancel",
      label: I18n.t("phrases.cancel"),
      onAction: ev => {
        this.closeModalFn();
      },
      rendersIn: UiActionRenderers.BUTTON,
      componentProps: {
        type: ButtonTypes.OUTLINE_PRIMARY,
        className: ""
      }
    },
    {
      id: "save",
      label: I18n.t("phrases.save"),
      onAction: async () => {
        let descriptionValue = this.formModel.getFormKeyExtractedValue()["description"];
        this.closeModalFn();
        await this.saveFN(descriptionValue);
      },
      rendersIn: UiActionRenderers.BUTTON,
      componentProps: {
        type: ButtonTypes.PRIMARY,
        className: "ml-2"
      }
    }
  ];

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
    this.isLoading = false;
  };

  @action
  loadImpact = async (impactId: number) => {
    let res = await this.impactsProvider.getDetailedById(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;

    this.setImpact(res.payload);
  };
}
