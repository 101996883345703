import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { SingleForm } from "../../../../../../../change/forms/singleFormModel/SingleForm_view";
import { ProcessAppsSidebarModel } from "./ProcessAppsSidebar_model";

export const ProcessAppsSidebar: React.FC<{ process: FP.Entities.IProjectProcess }> = observer(({ process }) => {
  const { organisationId, projectId } = useParams();
  const [model] = useState(() => new ProcessAppsSidebarModel(+organisationId, +projectId, process));

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
});
