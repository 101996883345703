import React from "react";
import { FORM_COL } from "../../../../../../../constants";
import { IRTEditorModel } from "../../../../../../../core/forms/controls/rteditor/IRTEditorModel";
import { INIT_RTEDITOR } from "../../../../../../../core/forms/controls/rteditor/RTEditor_model";
import { generateFormFieldsFromJson } from "../../../../../../../core/forms/helpers/FormFieldMappers";
import I18n from "../../../../../../../core/localization/I18n";

export const getImpactDescriptionFormFields = (value: string) => {
  const summary: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "description",
    label: <label htmlFor="description">{I18n.t("forms.description")}</label>,
    placeholder: I18n.t("placeholders.description"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: value
  };

  const fields = [];
  fields.push(summary);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
