import { UiSizes } from "@flightpath/coreui/dist/enums";
import { Button, ButtonIcon, ButtonIconShapes, ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import ModalContext from "../../../../../../../../core/modalZ/context/ModalContext";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import I18n from "../../../../../../../../core/localization/I18n";
import { Panel } from "../../../../../../../../components/ui/Panel";

export interface IActivityProcessTemplateSelectionView {
  handleNextStep: (template: number) => void;
  templates: any;
}

export const ActivityProcessTemplateSelectionView: React.FC<
  ReactProps & IActivityProcessTemplateSelectionView
> = props => {
  const { templates, handleNextStep } = props;
  return (
    <div>
      <div className="row">
        <div className="col-10 p-4 justify-content-start">{I18n.t("phrases.selectProcessTemplate")}</div>
        <div className="col-2 d-flex align-items-center justify-content-end">
          <ButtonIcon
            size={UiSizes.SM}
            onClick={ModalContext.hide}
            type={ButtonTypes.OUTLINE_PRIMARY}
            shape={ButtonIconShapes.RECTANGLE}
            symbol={IconSymbols.Close}
          />
        </div>
      </div>
      <div className="row">
        <div className="column p-4">
          {Object.keys(templates).map(e => (
            <Panel.Panel hasBorder hasShadow className="p-2">
              <div className="my-3 text-center">
                <img src={appConfig.baseUrl + templates[e].image} alt="" style={{ width: "120px" }} />
              </div>
              <Button onClick={() => handleNextStep(+e)}>{templates[e].name}</Button>
            </Panel.Panel>
          ))}
        </div>
      </div>
    </div>
  );
};
