import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { Enums, StakeholderType } from "../../../../../../../enums";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../services/api/v2/impacts/Impacts.api";

export class ImpactStakeholdersSidebarModel extends BaseModel {
  impactsProvider: IImpactsApi;
  @observable isLoading: boolean = true;
  @observable isSearchMode: boolean = false;
  @observable.ref projectStakeholders: FP.Entities.IProjectStakeholder[];
  @observable.ref filteredProjectStakeholders: FP.Entities.IProjectStakeholder[];
  @observable searchValue: string = "";
  stakeholderType: StakeholderType;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;
  impactId: number;

  constructor(
    closeModalFn: () => void,
    organisationId: number,
    projectId: number,
    impactId: number,
    stakeholderType: StakeholderType
  ) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.stakeholderType = stakeholderType;
    this.impactsProvider = ImpactsApi;
    this.isLoading = true;
    this.impactId = impactId;
    this.loadProjectStakeholders(impactId);
    this.closeModalFn = closeModalFn;
  }

  onUnmount = () => {
    this.resetSearch();
  };

  @action
  resetSearch = () => {
    this.searchValue = "";
    this.filterProjectStakeholders();
    this.hideSearchMode();
  };

  @action
  hideSearchMode = () => {
    this.isSearchMode = false;
  };

  @action
  showSearchMode = () => {
    this.isSearchMode = true;
  };

  @action
  updateSearchValue = (e: React.FormEvent<HTMLInputElement>) => {
    this.searchValue = e.currentTarget.value;
    this.filterProjectStakeholders();
  };

  @action
  filterProjectStakeholders = () => {
    if (this.searchValue) {
      const lowerSearch = this.searchValue.toLowerCase();
      this.filteredProjectStakeholders = this.projectStakeholders.filter(pStakeholder => {
        const name =
          pStakeholder.stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL
            ? `${pStakeholder.stakeholder.firstName} ${pStakeholder.stakeholder.lastName}`
            : pStakeholder.stakeholder.firstName;
        const lowerName = name.toLowerCase();

        return lowerName.includes(lowerSearch);
      });
    } else {
      this.filteredProjectStakeholders = this.projectStakeholders;
    }
  };

  @action
  setImpact = (projectStakeholders: FP.Entities.IProjectStakeholder[]) => {
    this.projectStakeholders = projectStakeholders.filter(x => x.stakeholder.stakeholderType === this.stakeholderType);
    this.filterProjectStakeholders();

    this.isLoading = false;
  };

  @action
  loadProjectStakeholders = async (impactId: number) => {
    let res = await this.impactsProvider.getStakeholders(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;

    this.setImpact(res.payload);
  };

  removeProjectStakeholder = async (stakeholderId: number) => {
    let res = await this.impactsProvider.removeStakeholder(
      this.organisationId,
      this.projectId,
      this.impactId,
      stakeholderId
    );
    if (!res || res.isError) return;

    this.loadProjectStakeholders(this.impactId);
  };
}
