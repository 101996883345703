import { ColDef } from "ag-grid-community";
import { colSelected } from "../../../../../../core/grids/CommonGridColumns";
import { IGridFns } from "../../../../../../core/grids/GridFunctions";
import {
  colActionNames,
  colImpactGroups,
  colImpactNames,
  colJobRoles,
  colProcessApps,
  colProcessL1,
  colProcessL2,
  colProcessL3,
  colProcessRoles,
  colScopeItems
} from "./ActivityProcessesGridView_baseColumns";
import { IActivityProcessGridModals } from "./modals/ActivityProcessesGridView_modals";

export interface IGetActivityProcessGridViewColumns {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  gridFns: IGridFns;
  pinned: "left" | "right" | null;
  modals: IActivityProcessGridModals;
}

export const GetActivityProcessGridViewColumns = (modelProps: IGetActivityProcessGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];

  result.push(colSelected());
  result.push(colProcessL1());
  result.push(colProcessL2());
  result.push(colProcessL3());
  result.push(colScopeItems());
  result.push(colProcessApps(modelProps.canEdit, modelProps.canEdit && modelProps.modals.showProcessAppModalFn));
  result.push(colProcessRoles(modelProps.canEdit, modelProps.canEdit && modelProps.modals.showProcessRoleModalFn));
  result.push(colJobRoles());
  result.push(
    colImpactGroups(modelProps.canEdit, modelProps.canEdit && modelProps.modals.showActivityProcessImpactGroupsModalFn)
  );
  result.push(colImpactNames());
  result.push(colActionNames());

  return result;
};

export const GenerateColumnDefinitions = (modelProps: IGetActivityProcessGridViewColumns): ColDef[] => {
  return GetActivityProcessGridViewColumns(modelProps);
};
