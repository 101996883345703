import { ColDef } from "ag-grid-community";
import { baseDropdownColumn, baseTextColumn } from "../../../../../../core/grids/BaseColumns";
import I18n from "../../../../../../core/localization/I18n";
import { GridPills } from "../../../../../../components/widgets/gridPills/GridPills";
import { openModalOnDelete } from "../../../../../../core/grids/GridHelpers";

export const colProcessL1 = () => {
  let res: ColDef = {
    ...baseTextColumn({ fieldName: "processL1", heading: I18n.t("grids.activityProcessL1"), clickEditable: false }),
    editable: false,
    autoHeight: true,
    width: 400
  };
  return res;
};
export const colProcessL2 = () => {
  let res: ColDef = {
    ...baseTextColumn({ fieldName: "processL2", heading: I18n.t("grids.activityProcessL2"), clickEditable: false }),
    editable: false,
    autoHeight: true,
    width: 400
  };

  return res;
};
export const colProcessL3 = () => {
  let res: ColDef = {
    ...baseTextColumn({ fieldName: "processL3", heading: I18n.t("grids.activityProcessL3"), clickEditable: false }),
    editable: false,
    autoHeight: true,
    width: 400
  };

  return res;
};

export const colScopeItems = (): ColDef => {
  let result: ColDef = {
    ...baseTextColumn({
      fieldName: "scopeItemNames",
      heading: I18n.t("grids.scopeItemNames"),
      width: 120,
      editable: false,
      autoHeight: true,
      clickEditable: false
    }),
    cellRenderer: e => <GridPills text={e.data.scopeItemNames} />
  };

  return result;
};

export const colImpactNames = () => {
  let result: ColDef = {
    ...baseTextColumn({
      fieldName: "impactNames",
      heading: I18n.t("grids.impacts"),
      width: 500,
      editable: false,
      autoHeight: true,
      clickEditable: false
    }),
    cellRenderer: e => {
      return <GridPills text={e.data.impactNames} />;
    }
  };

  return result;
};

export const colActionNames = () => {
  let result: ColDef = {
    ...baseTextColumn({
      fieldName: "actionNames",
      heading: I18n.t("grids.actions"),
      width: 500,
      editable: false,
      autoHeight: true,
      clickEditable: false
    }),
    cellRenderer: e => {
      return <GridPills text={e.data.actionNames} />;
    }
  };

  return result;
};

export const colImpactGroups = (canEdit: boolean, editFn?: any): ColDef => {
  let res: ColDef = {
    ...baseDropdownColumn({
      fieldName: "impactGroupNames",
      heading: I18n.t("grids.impactGroups"),
      autoHeight: true,
      width: 400,
      clickEditable: true
    }),
    cellRenderer: e => <GridPills text={e.data.impactGroupNames} />
  };

  const isEditable = canEdit && editFn !== undefined;
  if (isEditable && editFn) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }

  return res;
};

export const colProcessRoles = (canEdit: boolean, editFn?: any): ColDef => {
  let result: ColDef = {
    ...baseTextColumn({
      fieldName: "processRoles",
      heading: I18n.t("grids.processRoles"),
      width: 170,
      editable: false,
      autoHeight: true,
      clickEditable: true
    }),
    cellRenderer: e => {
      return <GridPills text={e.data.processRoles} />;
    }
  };

  const isEditable = canEdit && editFn !== undefined;
  if (isEditable && editFn) {
    result.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }

  return result;
};

export const colProcessApps = (canEdit: boolean, editFn?: any): ColDef => {
  let result: ColDef = {
    ...baseTextColumn({
      fieldName: "processApps",
      heading: I18n.t("grids.processApps"),
      width: 170,
      editable: false,
      autoHeight: true,
      clickEditable: true
    }),
    cellRenderer: e => <GridPills text={e.data.processApps} />
  };

  const isEditable = canEdit && editFn !== undefined;
  if (isEditable && editFn) {
    result.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    result.suppressKeyboardEvent = openModalOnDelete(result, editFn);
  }

  return result;
};

export const colJobRoles = (): ColDef => {
  let result: ColDef = {
    ...baseTextColumn({
      fieldName: "processJobRoles",
      heading: I18n.t("grids.processJobRoles"),
      width: 170,
      editable: false,
      autoHeight: true,
      clickEditable: false
    }),
    cellRenderer: e => {
      return <GridPills text={e.data.processJobRoles} />;
    }
  };

  return result;
};
