import React, { useContext } from "react";
import { AgGridContextModel, IAgGridContextModel } from "./agGridContextModel";

const trialLicenseKey =
  "[TRIAL]_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-055961}_is_granted_for_evaluation_only___Use_in_production_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_purchasing_a_production_key_please_contact_info@ag-grid.com___You_are_granted_a_{Single_Application}_Developer_License_for_one_application_only___All_Front-End_JavaScript_developers_working_on_the_application_would_need_to_be_licensed___This_key_will_deactivate_on_{30 April 2024}____[v3]_[0102]_MTcxNDQzMTYwMDAwMA==90280a7fd202910f8630bdd10f5b2a35";
let AgGridContext = new AgGridContextModel(trialLicenseKey);

const AgGridReactContext = React.createContext<AgGridContextModel>(AgGridContext);

export const AgGridContextProvider: React.FC<ReactProps> = ({ children }) => (
  <AgGridReactContext.Provider value={AgGridContext}>{children}</AgGridReactContext.Provider>
);

export function useAgGridContext() {
  return useContext(AgGridReactContext);
}
export default AgGridContext as IAgGridContextModel;
