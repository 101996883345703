import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import { Button, ButtonIcon } from "../../../../components/ui/Button";
import I18n from "../../../../core/localization/I18n";
import { UiSizes } from "../../../../enums";
import { ISingleFormModel } from "../singleFormModel/SingleForm_model";
import { SingleForm } from "../singleFormModel/SingleForm_view";
import { IconSymbols } from "../../../../components/ui/Icon";
import { IF } from "../../../../components/hoc/If";

interface MicroFormProps {
  formModel: ISingleFormModel;
  buttonPlaceholder?: string;
  className?: string;
  iconSymbol?: IconSymbols;
  onSubmit: () => void;
  fieldName?: string;
  shouldHideCreateButton?: boolean;
}

export const MicroForm: React.FC<MicroFormProps> = observer(props => {
  const [isActive, setIsActive] = useState(false);
  const { onSubmit, formModel, buttonPlaceholder } = props;

  const nameInputRef = useRef(null);
  const nameInput = props.formModel.formFields.find(e => e.key === props.fieldName);
  nameInput.ref = nameInputRef;
  const inputHasLength = nameInput.value?.length > 0;

  const maybeCloseForm = e => {
    e.preventDefault();
    if (inputHasLength || e.relatedTarget?.id === "CreateAnother" || e.target?.id === "CreateAnother") {
      return;
    }
    setTimeout(() => {
      setIsActive(false);
    }, 300);
  };

  const submitAndStartAgain = () => {
    onSubmit();
    nameInputRef.current.focus();
  };

  const closeFormOnEsc = (e: React.KeyboardEvent<any>) => {
    if (e.key === "Escape") {
      setIsActive(false);
    }
  };

  return (
    <div className={`micro-form ${props.className || ""}`} tabIndex={0} onBlur={e => maybeCloseForm(e)}>
      <IF condition={!isActive}>
        {!props.iconSymbol ? (
          <Button
            id={`MicroFormButton`}
            onClick={() => {
              setIsActive(!isActive);
              formModel.resetFields();
            }}
            className="align-self-start"
            size={UiSizes.SM}
          >
            {buttonPlaceholder}
          </Button>
        ) : (
          <ButtonIcon
            id={`MicroFormButton`}
            onClick={() => {
              setIsActive(!isActive);
              formModel.resetFields();
            }}
            className="align-self-start"
            size={UiSizes.SM}
            symbol={props.iconSymbol}
          ></ButtonIcon>
        )}
      </IF>

      {isActive && (
        <div className={`d-flex`} onKeyUp={(e: React.KeyboardEvent<any>) => closeFormOnEsc(e)}>
          <div className="micro-form__input d-flex">
            <div className="mx-2">
              <SingleForm model={formModel}></SingleForm>
            </div>
            {!props.shouldHideCreateButton && (
              <Button
                id="CreateAnother"
                size={UiSizes.SM}
                className="align-self-start"
                onClick={async e => {
                  submitAndStartAgain();
                }}
              >
                {I18n.t("phrases.create")}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
});
