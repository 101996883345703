import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button } from "../../../../../../components/ui/Button";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { StakeholderEmailModel } from "./StakeholderEmail_model";

export const StakeholderEmail: React.FC<{ impactIds: number[]; projectId: number }> = observer(
  ({ impactIds, projectId }) => {
    const organisationId = useCurrentOrganisationId();
    const [model] = useState(() => new StakeholderEmailModel(organisationId, projectId, impactIds));

    const emailList = model.stakeholders.map(e => e.email).join(",");

    useEffect(() => {
      model.onMount();
    }, [model]);

    if (model.isLoading) {
      return (
        <div className="stakeholder-email container-fluid py-6">
          <PositionedSpinner />
        </div>
      );
    }

    return (
      <div className="stakeholder-email container-fluid py-6">
        <div className="row">
          <div className="col">
            <h1 className="mb-1">Email Addresses</h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ul className="my-0 mb-3">
              {model.stakeholders?.length > 0
                ? model.stakeholders.map((email, i) => {
                    return (
                      <li className="stakeholder-email__list" key={i}>
                        <p className="mb-0">{email}</p>
                      </li>
                    );
                  })
                : I18n.t("phrases.impactStakeholders_noEmailAddresses")}
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Button
              onClick={() => {
                navigator.clipboard.writeText(emailList);
              }}
              className="mr-2"
            >
              {I18n.t("phrases.copyToClipboard")}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);
