import { AxiosInstance } from "axios";
import http from "../Http";
import { ExportableProjectApi } from "../exportableApi/ExportableProjectApiModel";

export class ActivityProcessesApi extends ExportableProjectApi<any> {
  controller: string = "activity-processes";

  constructor(http: AxiosInstance) {
    super(http, "activity-processes");
  }

  getRolesByOrgId = async (organisationId: number, projectId: number, config?: any) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/process-roles`,
      config
    );
    return res.data;
  };

  getAppsByOrgId = async (organisationId: number, projectId: number, config?: any) => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/process-apps`,
      config
    );
    return res.data;
  };
}

const instance = new ActivityProcessesApi(http);
export default instance;
