import { ColDef } from "ag-grid-community";
import {
  colCreatedByName,
  colCreatedOn,
  colDescription,
  colDynamicRichText,
  colEndDate,
  colLastReviewedOn,
  colModifiedByName,
  colName,
  colNextReviewDue,
  colNoteCount,
  colOwner,
  colProgressStatus,
  colRefNumber,
  colReviewedBy,
  colSelected,
  colStartDate,
  colTags,
  colUpdatedOn
} from "../../../../../core/grids/CommonGridColumns";
import { IGridFns } from "../../../../../core/grids/GridFunctions";
import {
  GridSideModalTitle,
  gridSideModalAnimationOpts,
  gridSideModalComponentProps
} from "../../../../../core/grids/GridSideModals";
import I18n from "../../../../../core/localization/I18n";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import { EntityTypes, ImpactField } from "../../../../../enums";
import CustomFieldsApi from "../../../../../services/api/v2/customFields/CustomFields.api";
import {
  colActions,
  colAudiences,
  colBusinessAreas,
  colImpactGroups,
  colImpactLevel,
  colImpactTypes,
  colLocations,
  colMilestones,
  colProcesses,
  colProjectStakeholders,
  colTotalPeopleImpacted
} from "./ImpactGridView_baseColumns";
import { IImpactGridModals } from "./modals/ImpactGridView_modals";
import { ImpactRichTextSidebarModel } from "./modals/impactRichTextSidebar/ImpactRichTextSidebar_model";
import { ImpactRichTextSidebar } from "./modals/impactRichTextSidebar/ImpactRichTextSidebar_view";

export interface IGetImpactGridViewColumns {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  projectTeamMembers: FP.Entities.IUser[];
  progressStatuses: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  gridFns: IGridFns;
  pinned: "left" | "right" | null;
  modals: IImpactGridModals;
  userCanViewActions: boolean;
  userCanViewStakeholders: boolean;
}

export const GetImpactGridViewColumns = (modelProps: IGetImpactGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];
  const modals = modelProps.modals;
  const canEdit = modelProps.canEdit;

  result.push(colSelected());

  result.push(
    colRefNumber(modelProps.organisationId, modelProps.projectId, EntityTypes.IMPACTS, { pinned: modelProps.pinned })
  );
  result.push(
    colName(canEdit, canEdit && modelProps.gridFns.updateTextField, ImpactField.name, "", {
      width: 300,
      pinned: modelProps.pinned
    })
  );

  result.push(colDescription(canEdit, canEdit && modals.showDescriptionModalFn));
  result.push(
    colProgressStatus(
      canEdit,
      modelProps.progressStatuses,
      modelProps.gridFns.updateIdField,
      ImpactField.progressStatus,
      {}
    )
  );
  result.push(colStartDate(canEdit, canEdit && modelProps.gridFns.updateDateField, ImpactField.startDate, {}));
  result.push(colEndDate(canEdit, canEdit && modelProps.gridFns.updateDateField, ImpactField.endDate, {}));
  result.push(
    colOwner(canEdit, modelProps.projectTeamMembers, modelProps.gridFns.updateIdField, ImpactField.owner, {})
  );
  result.push(colImpactTypes(canEdit, canEdit && modals.showTypesModalFn));
  result.push(colImpactLevel(canEdit, canEdit && modelProps.impactLevels, modelProps.gridFns.updateNumericField));
  result.push(colTotalPeopleImpacted(canEdit, canEdit && modelProps.gridFns.updateNumericField));
  result.push(colImpactGroups(canEdit, canEdit && modals.showGroupsModalFn));

  //Only add these columns if the current user has permissions to see the connected data
  if (modelProps.userCanViewStakeholders) {
    result.push(colProjectStakeholders(canEdit, canEdit && modals.showStakeholdersModalFn));
    result.push(colAudiences(canEdit, canEdit && modals.showAudiencesModalFn));
  }
  result.push(colBusinessAreas(canEdit, canEdit && modals.showBusinessAreasModalFN));
  result.push(colLocations(canEdit, canEdit && modals.showLocationsModalFN));
  result.push(colActions(canEdit, canEdit && modals.showActionModalFN));
  result.push(colMilestones(canEdit, canEdit && modals.showMilestonesModalFn));
  result.push(colProcesses(canEdit, canEdit && modals.showProcessesModalFn));
  result.push(colNoteCount(canEdit, canEdit && modals.showNotesModalFn));
  result.push(colTags(canEdit, canEdit && modals.showTagsModalFn));
  result.push(colReviewedBy());
  result.push(colLastReviewedOn());
  result.push(colNextReviewDue());
  result.push(colCreatedByName());
  result.push(colCreatedOn());
  result.push(colModifiedByName());
  result.push(colUpdatedOn());
  return result;
};

export const GetImpactGridViewDictionary = (modelProps: IGetImpactGridViewColumns): Dictionary<ColDef> => {
  const modals = modelProps.modals;
  const canEdit = modelProps.canEdit;
  const result = {
    refNumber: colRefNumber(modelProps.organisationId, modelProps.projectId, EntityTypes.IMPACTS, {
      pinned: modelProps.pinned,
      clickEditable: true
    }),
    name: colName(canEdit, canEdit && modelProps.gridFns.updateTextField, ImpactField.name, "", {
      width: 300,
      pinned: modelProps.pinned,
      clickEditable: true
    }),
    description: colDescription(canEdit, canEdit && modals.showDescriptionModalFn),
    progressStatus: colProgressStatus(
      canEdit,
      modelProps.progressStatuses,
      modelProps.gridFns.updateIdField,
      ImpactField.progressStatus,
      {}
    ),
    startDate: colStartDate(canEdit, canEdit && modelProps.gridFns.updateDateField, ImpactField.startDate, {}),
    endDate: colEndDate(canEdit, canEdit && modelProps.gridFns.updateDateField, ImpactField.endDate, {}),
    owner: colOwner(
      canEdit,
      canEdit && modelProps.projectTeamMembers,
      modelProps.gridFns.updateIdField,
      ImpactField.owner,
      {}
    ),
    impactTypes: colImpactTypes(canEdit, canEdit && modals.showTypesModalFn),
    impactLevel: colImpactLevel(canEdit, canEdit && modelProps.impactLevels, modelProps.gridFns.updateNumericField),
    totalPeopleImpacted: colTotalPeopleImpacted(canEdit, canEdit && modelProps.gridFns.updateNumericField),
    impactGroups: colImpactGroups(canEdit, canEdit && modals.showGroupsModalFn),
    businessAreas: colBusinessAreas(canEdit, canEdit && modals.showBusinessAreasModalFN),
    locations: colLocations(canEdit, canEdit && modals.showLocationsModalFN),
    actions: colActions(canEdit, canEdit && modals.showActionModalFN),
    milestones: colMilestones(canEdit, canEdit && modals.showMilestonesModalFn),
    processes: colProcesses(canEdit, canEdit && modals.showProcessesModalFn),
    noteCount: colNoteCount(canEdit, canEdit && modals.showNotesModalFn),
    tags: colTags(canEdit, canEdit && modals.showTagsModalFn),
    projectStakeholders: colProjectStakeholders(canEdit, canEdit && modals.showStakeholdersModalFn),
    audiences: colAudiences(canEdit, canEdit && modals.showAudiencesModalFn),
    reviewedBy: colReviewedBy(),
    reviewedOn: colLastReviewedOn(),
    nextReviewDue: colNextReviewDue(),
    createdByName: colCreatedByName(),
    createdOn: colCreatedOn(),
    modifiedByName: colModifiedByName(),
    updatedOn: colUpdatedOn(),
    selected: colSelected()
  };

  return result;
};

export const GenerateColumnDefinitions = (
  colDefs: FP.Entities.IColumnDef[],
  modelProps: IGetImpactGridViewColumns
): ColDef[] => {
  if (!colDefs || colDefs.length === 0) {
    return GetImpactGridViewColumns(modelProps);
  }
  const config = GetImpactGridViewDictionary(modelProps);
  let res = colDefs.map(e => {
    if (!config[e.colName] && e.isCore) {
      return null;
    }
    if (e.isCore) {
      return config[e.colName];
    }
    return GetDynamicColDef(modelProps.organisationId, modelProps.projectId, e);
  });
  if (modelProps.canEdit) {
    res.unshift(config["selected"]);
  }
  return res;
};

export const GetDynamicColDef = (organisationId: number, projectId: number, columnDef: FP.Entities.IColumnDef) => {
  const customFieldProvider = CustomFieldsApi;

  return colDynamicRichText(columnDef.colName, columnDef.header, true, async data => {
    const currentValue = data[columnDef.colName];
    const saveFN = newValue =>
      customFieldProvider.updateImpactCustomFieldValue(
        organisationId,
        projectId,
        columnDef.customFieldId,
        data.id,
        newValue
      );
    ModalContext.show({
      showClose: true,
      title: <GridSideModalTitle name={data.name} field={I18n.t("phrases.description")} />,
      content: (
        <ImpactRichTextSidebar
          model={
            new ImpactRichTextSidebarModel(ModalContext.hide, organisationId, projectId, data.id, currentValue, saveFN)
          }
        />
      ),
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  });
};
