import { makeObservable, observable } from "mobx";
import { IGridModel } from "../../../../../../../core/grids/IGridModel";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { GridTypes } from "../../../../../../../enums";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import ActivityProcessesHub, {
  ActivityProcessesHub as IActivityProcessesHub
} from "../../../../../../../services/hubs/ActivityProcessesHub/ActivityProcesses_hub";
import { GetActivityProcessGridViewColumns } from "../../ActivityProcessesGridView/ActivityProcessesGridView_columns";

export class ActivityProcessTemplateSelectionGridViewModel extends BaseModel implements IGridModel {
  projectProcessProvider: IProjectProcessesApi;
  activityProcessHub: IActivityProcessesHub;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  type: GridTypes = GridTypes.ACTION_IMPACT_GRID;
  organisationId: number;
  projectId: number;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    super();
    makeObservable(this);
    this.projectProcessProvider = ProjectProcessesApi;
    this.activityProcessHub = ActivityProcessesHub;
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
  }

  onMount = () => {};

  onUnmount = () => {};

  getColumnConfig = () => {
    return GetActivityProcessGridViewColumns({
      canEdit: false,
      organisationId: this.organisationId,
      projectId: this.projectId,
      gridFns: null,
      modals: null,
      pinned: null
    });
  };
}
