import { GridBaseModel } from "../common/GridBaseModel";
import { HubEvents } from "../common/HubEventsEnum";

export class ActivityProcessesHub extends GridBaseModel {
  hubName: string = "activity-processes";

  constructor() {
    super("activity-processes");
  }
  onLoadData = callback => this.registerIncomingEvent(HubEvents.ON_LOAD_ACTIVITY_PROCESSES_DATA, callback);

  invokeLoadData = async (organisationId: number, projectId: number) => {
    if (!this.isConnectionStarted) return;
    await this.connection.invoke(HubEvents.INVOKE_LOAD_ACTIVITY_PROCESSES_DATA, {
      organisationId: organisationId,
      projectId: projectId
    });
  };
}
const instance = new ActivityProcessesHub();
export default instance;
