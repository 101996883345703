import {
  gridSideModalAnimationOpts,
  gridSideModalComponentProps,
  GridSideModalTitle
} from "../../../../../../../core/grids/GridSideModals";
import I18n from "../../../../../../../core/localization/I18n";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";
import { ActivityProcessImpactGroupsSidebar } from "./ActivityProcessImpactGroupsSidebar/ActivityProcessImpactGroupsSidebar_view";
import { ProcessAppsSidebar } from "./ProcessAppsSidebar/ProcessAppsSidebar_view";
import { ProcessRolesSidebar } from "./ProcessRolesSidebar/ProcessRolesSidebar_view";

export interface IActivityProcessGridModals {
  showActivityProcessImpactGroupsModalFn?: (activityProcess: FP.Entities.IProjectProcess) => void;
  showProcessAppModalFn: (process: FP.Entities.IProjectProcess) => void;
  showProcessRoleModalFn: (process: FP.Entities.IProjectProcess) => void;
}

export const getProcessGridModals = (organisationId: number, projectId: number): IActivityProcessGridModals => {
  const modalService = ModalContext;

  const activityProcessImpactGroupsModal = (activityProcess: FP.Entities.IProjectProcess) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={activityProcess.name} field={I18n.t("phrases.impactGroups")} />,
      content: <ActivityProcessImpactGroupsSidebar projectProcessId={activityProcess.id} />,
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };
  const processAppModal = (activityProcess: FP.Entities.IProjectProcess) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={activityProcess.name} field={I18n.t("phrases.processApps")} />,
      content: <ProcessAppsSidebar process={activityProcess} />,
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };
  const processRoleModal = (activityProcess: FP.Entities.IProjectProcess) => {
    modalService.show({
      showClose: true,
      title: <GridSideModalTitle name={activityProcess.name} field={I18n.t("phrases.processRoles")} />,
      content: <ProcessRolesSidebar process={activityProcess} />,
      componentProps: gridSideModalComponentProps,
      animationOptions: gridSideModalAnimationOpts
    });
  };

  const ActivityProcessGridModals: IActivityProcessGridModals = {
    showActivityProcessImpactGroupsModalFn: activityProcessImpactGroupsModal,
    showProcessAppModalFn: processAppModal,
    showProcessRoleModalFn: processRoleModal
  };

  return ActivityProcessGridModals;
};
