import { Enums, UiSizes } from "@flightpath/coreui/dist/enums";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { Button, ButtonIcon, ButtonIconShapes, ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import { Input } from "@flightpath/coreui/dist/ui/_forms/Input";
import { observer } from "mobx-react";
import { IF } from "../../../../../../../../components/hoc/If";
import { GridView } from "../../../../../../../../core/grids/GridView_view";
import ModalContext from "../../../../../../../../core/modalZ/context/ModalContext";
import { ActivityProcessTemplateSelectionGridViewModel } from "../ActivityProcessTemplateSelectionGrid_model";
import { ActivityProcessTemplateSelectionModel } from "../ActivityProcessTemplateSelection_model";
import { useRef } from "react";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import I18n from "../../../../../../../../core/localization/I18n";

export interface IL3ProcessSelectionGridView {
  model: ActivityProcessTemplateSelectionModel;
  gridModel: ActivityProcessTemplateSelectionGridViewModel;
  handlePreviousStep: () => void;
  templateProcesses: any;
}

export const L3ProcessSelectionGridView: React.FC<ReactProps & IL3ProcessSelectionGridView> = observer(props => {
  const { model, gridModel, handlePreviousStep, templateProcesses } = props;
  const filterRef = useRef(null);

  return (
    <div className="col">
      <IF condition={!model.assignmentInProgress}>
        <div className="row p-4 justify-content-start">{I18n.t("phrases.selectL3Processes")}</div>
        <div className="row">
          <div className="col-10 d-flex">
            <Button
              type={ButtonTypes.OUTLINE_PRIMARY}
              size={UiSizes.SM}
              isDisabled={model.selectedActivityProcesses?.length === 0}
              className="mt-2 mr-2 align-self-start"
              onClick={async () => {
                let res = await model.assignActivityProcesses(templateProcesses);
                if (res) {
                  ModalContext.hide();
                }
              }}
            >
              {I18n.t("phrases.assign")}
            </Button>
            <Input
              autoFocus={true}
              className="mt-2"
              size={UiSizes.XS}
              onChange={model.setSearchText}
              placeholder="Search"
              icon={IconSymbols.Search}
              iconSize={Enums.UiSizes.SM}
              iconClass="mt-1"
              style={{ height: 32 }}
            />
          </div>
          <div className="col-2 d-flex align-items-end px-0 justify-content-end">
            <ButtonIcon
              size={UiSizes.SM}
              onClick={ModalContext.hide}
              type={ButtonTypes.OUTLINE_PRIMARY}
              shape={ButtonIconShapes.RECTANGLE}
              symbol={IconSymbols.Close}
            />
          </div>
          <div className="col-12 d-flex align-items-end justify-content-start">
            <Button
              type={ButtonTypes.OUTLINE_PRIMARY}
              size={UiSizes.SM}
              className="mt-2 mr-2 align-self-start"
              onClick={() => handlePreviousStep()}
            >
              {I18n.t("phrases.back")}
            </Button>
          </div>
        </div>
        <div className="row p-4" style={{ maxHeight: "60vh", overflow: "auto" }}>
          <IF condition={model && !model.isLoading && !model.assignmentInProgress}>
            <GridView
              context={{}}
              data={model.activityProcessesRowData}
              users={[]}
              onCellClicked={() => {}}
              onCellEditModeChange={() => {}}
              onSelectionChanged={model.updateSelectedActivityProcesses}
              model={gridModel}
              isLoading={model.isLoading}
              onGridReady={model.onGridReady}
              ref={filterRef}
              // filterHasChangedFn={setHasFilters}
              // isExternalFilterPresent={model.isExternalFilterPresent}
              // doesExternalFilterPass={model.doesExternalFilterPass}
              overlayNoRowsTemplate={null}
            />
          </IF>
        </div>
      </IF>
      <IF condition={model.assignmentInProgress}>
        <div className="d-flex flex-column p-3 align-items-center">
          <PositionedSpinner></PositionedSpinner>
          <p className="p-3">{I18n.t("phrases.assignProcessesLoadingText")}</p>
        </div>
      </IF>
    </div>
  );
});
