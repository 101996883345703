import * as GridFilterHelpers from "../../../../../core/grids/GridFilterHelpers";
import * as GridQueryStringHelpers from "../../../../../core/grids/GridQueryStringHelpers";

export const getActivityProcessGridFilters = () => {
  const createdBy: string = GridQueryStringHelpers.getCreatedByParameter();

  let filter = {};

  if (createdBy !== null) {
    filter["createdByName"] = GridFilterHelpers.textEquals_Filter(createdBy);
  }

  return filter;
};
