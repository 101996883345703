import { NavigateFunction } from "react-router-dom";
import Pages from "../../../../routes/InsightRoutes";

export class ImpactReportingViewChartProps {
  navigate: NavigateFunction;
  constructor(organisationId: number, projectId: number, navigate: NavigateFunction) {
    this.organisationId = organisationId;
    this.navigate = navigate;
    this.projectId = projectId;
  }

  organisationId: number;
  projectId: number;
  Data: any;
  DataConfidence: number = 0;
  IsLoading: boolean = true;

  setData = chartData => {
    this.Data = chartData.data;
    this.DataConfidence = chartData.dataConfidence;
    this.IsLoading = false;
  };

  gapAnalysisOnClickFunction = (gapAnalysisType: string) => (node, event) => {
    const url = `${Pages.projects.impacts.listView.generateLink(
      this.organisationId,
      this.projectId
    )}?ga=${gapAnalysisType}&value=${node.id}`;
    this.navigate(url);
  };

  drillThroughOnClickFunction = (drillThroughType: string) => (data, event) => {
    const url = `${Pages.projects.impacts.listView.generateLink(this.organisationId, this.projectId)}/?level=${
      data.id
    }&${drillThroughType}=${encodeURIComponent(data.indexValue)}`;
    this.navigate(url);
  };
}
