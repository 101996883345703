import { action, makeObservable, observable } from "mobx";
import PermissionsContext from "../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";
import { ITreeDataItem } from "../../../../../../../core/forms/controls/searchTreePicker/ISearchTreePickerModel";

import { BaseModel } from "../../../../../../../core/util/BaseModel";
import ImpactTypesApi, {
  ImpactTypesApi as IImpactTypesApi
} from "../../../../../../../services/api/v2/impactTypes/ImpactTypes.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../services/api/v2/impacts/Impacts.api";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import {
  getImpactBusinessAreasSidebarFormActions,
  getImpactBusinessAreasSidebarFormFields
} from "./ImpactBusinessAreasSidebar_fields";

export class ImpactBusinessAreasSidebarModel extends BaseModel {
  impactTypesProvider: IImpactTypesApi;
  impactsProvider: IImpactsApi;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;
  businessAreas: ITreeDataItem[];
  formModel: SingleFormModel;
  canEdit: boolean;

  constructor(closeModalFn: () => void, organisationId: number, projectId: number, impactId: number) {
    super();
    makeObservable(this);
    this.impactTypesProvider = ImpactTypesApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = ImpactsApi;
    this.loadSidebar(impactId);
    this.closeModalFn = closeModalFn;
    this.canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, this.organisationId, this.projectId);
  }

  @action
  getBusinessAreas = async (impactId: number) => {
    let res = await this.impactsProvider.getBusinessAreaSelection(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.businessAreas = res.payload;
  };

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
  };

  @action
  loadImpact = async (impactId: number) => {
    let res = await this.impactsProvider.getDetailedById(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;

    this.setImpact(res.payload);
  };

  @action
  loadSidebar = async (impactId: number) => {
    this.isLoading = true;
    await this.loadImpact(impactId);
    await this.getBusinessAreas(impactId);
    this.loadForm();
    this.isLoading = false;
  };

  loadForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactBusinessAreasSidebarFormFields(this.businessAreas);
    this.formModel.setActions(
      getImpactBusinessAreasSidebarFormActions(this.updateImpact, this.canEdit, this.closeModalFn)
    );
  };

  updateImpact = async () => {
    if (!this.canEdit) return;
    this.formModel.isSaving = true;
    let res = await this.formModel.submit();
    if (!res) return;

    await this.impactsProvider.updateBusinessAreas(
      this.organisationId,
      this.projectId,
      this.impact.id,
      res.businessAreas
    );
  };
}
