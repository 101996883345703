import { action, makeObservable, observable } from "mobx";
import { IMPACT_LEVEL_OPTIONS, PROGRESS_STATUS_OPTIONS } from "../../../../../constants";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import { GetGridFns } from "../../../../../core/grids/GridFunctions";
import { IGridModel } from "../../../../../core/grids/IGridModel";
import I18n from "../../../../../core/localization/I18n";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { EntityTypes, ImpactLevel } from "../../../../../enums";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../services/api/v2/impacts/Impacts.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import ImpactsHub, { ImpactsHub as IImpactsHub } from "../../../../../services/hubs/ImpactsHub/Impacts_hub";
import { GenerateColumnDefinitions } from "./ImpactGridView_columns";
import { getImpactGridModals } from "./modals/ImpactGridView_modals";

export class ImpactGridViewModel extends BaseModel implements IGridModel {
  impactsProvider: IImpactsApi;
  impactsHub: IImpactsHub;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  impactLevels: FP.Generic.IKeyLabel[];
  type: EntityTypes = EntityTypes.IMPACTS;
  organisationId: number;
  projectId: number;
  userCanViewActions: boolean;
  userCanViewStakeholders: boolean;
  colDefs: FP.Entities.IColumnDef[];
  @observable.ref projectTeamMembers: any[];

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    colDefs: FP.Entities.IColumnDef[]
  ) {
    super();
    makeObservable(this);
    this.impactsProvider = ImpactsApi;
    this.impactsHub = ImpactsHub;
    this.colDefs = colDefs;
    this.projectTeamUserPermissionsProvider = ProjectTeamUserPermissionsApi;
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.mapProgressStatuses();
    this.mapImpactLevels();
    this.userCanViewActions = PermissionsContext.canViewField(PermissionFields.ACTIONS, organisationId, projectId);
    this.userCanViewStakeholders = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
  }

  onMount = async () => {
    await this.loadProjectTeamMembers();
    this.setIsLoading(false);
    // Promise.all([this.loadProjectTeamMembers()]).then(v => {
    //   this.setIsLoading(false);
    // });
  };

  onUnmount = () => {};

  getColumnConfig = () => {
    const canEditImpacts = PermissionsContext.canEditField(
      PermissionFields.IMPACTS,
      this.organisationId,
      this.projectId
    );

    const s = GenerateColumnDefinitions(this.colDefs, {
      canEdit: canEditImpacts,
      organisationId: this.organisationId,
      projectId: this.projectId,
      projectTeamMembers: this.projectTeamMembers,
      impactLevels: this.impactLevels,
      progressStatuses: this.progressStatuses,
      gridFns: GetGridFns(this.impactsProvider, this.organisationId, this.projectId),
      modals: getImpactGridModals(this.organisationId, this.projectId),
      userCanViewStakeholders: this.userCanViewStakeholders,
      userCanViewActions: this.userCanViewActions,
      pinned: "left"
    });
    return s;
  };

  mapProgressStatuses = () => {
    this.progressStatuses = PROGRESS_STATUS_OPTIONS.map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };

  mapImpactLevels = () => {
    this.impactLevels = IMPACT_LEVEL_OPTIONS.filter(e => e.key !== ImpactLevel.UNKNOWN).map(e => {
      return {
        label: I18n.t(e.label),
        key: e.key + ""
      } as FP.Generic.IKeyLabel;
    });
  };

  @action
  loadProjectTeamMembers = async () => {
    const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    this.setProjectTeamMembers(res.payload);
  };

  @action
  setProjectTeamMembers = projectTeamMembers => {
    this.projectTeamMembers = projectTeamMembers;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
