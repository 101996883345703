import * as GridFilterHelpers from "../../../../core/grids/GridFilterHelpers";
import * as GridQueryStringHelpers from "../../../../core/grids/GridQueryStringHelpers";

const createDateFirstOfMonth = (month: number, year: number) => {
  return new Date(year, month - 1, 1);
};

const createDateLastOfMonth = (month: number, year: number) => {
  return new Date(year, month, 0);
};

const dateToString = (date: Date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

const createStartDateFilterDate = (month: number, year: number) => {
  //capture whole of month so everything below first of next month
  const nextMonth = month + 1;
  const date = createDateFirstOfMonth(nextMonth, year);
  return dateToString(date);
};

const createEndDateFilterDate = (month: number, year: number) => {
  const previousMonth = month - 1;
  const date = createDateLastOfMonth(previousMonth, year);

  return dateToString(date);
};

const isEarlierThan_Filter = (month: number, year: number) => {
  if (month > 0 && year > 0) {
    const date = createStartDateFilterDate(month, year);
    return GridFilterHelpers.dateLessThan_Filter(date);
  }
};

const isLaterThan_Filter = (month: number, year: number) => {
  if (month > 0 && year > 0) {
    const date = createEndDateFilterDate(month, year);
    return GridFilterHelpers.dateGreaterThan_Filter(date);
  }
};

export const getImpactGridFilters = () => {
  const location: string = GridQueryStringHelpers.getLocationParameter();
  const businessArea: string = GridQueryStringHelpers.getBusinessAreaParameter();
  const month: number = GridQueryStringHelpers.getWithinMonthParameter();
  const year: number = GridQueryStringHelpers.getWithinYearParameter();
  const level: string = GridQueryStringHelpers.getLevelParameter();
  const type: string = GridQueryStringHelpers.getTypeParameter();
  const owner: string = GridQueryStringHelpers.getOwnerParameter();
  const progressStatus: string = GridQueryStringHelpers.getProgressStatusParameter();
  const createdBy: string = GridQueryStringHelpers.getCreatedByParameter();
  const reviewedBy: string = GridQueryStringHelpers.getReviewedByParameter();
  const projectStakeholderNames = GridQueryStringHelpers.getProjectStakeholderNames();
  const audienceNames = GridQueryStringHelpers.getAudienceNames();

  let filter = {};
  if (location !== null) {
    filter["locationNames"] = GridFilterHelpers.textEquals_Filter(location);
  }
  if (projectStakeholderNames) {
    const projectStakeholderNamesFromQuery = projectStakeholderNames.split("|");

    if (projectStakeholderNamesFromQuery?.length > 0) {
      filter["projectStakeholderNames"] = GridFilterHelpers.getMultiValueCondition_Filter(
        projectStakeholderNamesFromQuery,
        "contains",
        "OR"
      );
    } else {
      filter["projectStakeholderNames"] = GridFilterHelpers.textContains_Filter(projectStakeholderNames);
    }
  }

  if (businessArea !== null) {
    filter["businessAreaNames"] = GridFilterHelpers.textEquals_Filter(businessArea);
  }
  if (audienceNames !== null) {
    filter["audienceNames"] = GridFilterHelpers.textContains_Filter(audienceNames);
  }
  if (month > 0 && year > 0) {
    filter["startDate"] = GridFilterHelpers.getMultipleFilters_Filter(
      [isEarlierThan_Filter(month, year), GridFilterHelpers.notBlank_Filter()],
      "AND"
    );
    filter["endDate"] = GridFilterHelpers.getMultipleFilters_Filter(
      [isLaterThan_Filter(month, year), GridFilterHelpers.notBlank_Filter()],
      "AND"
    );
  }

  if (level !== null) {
    filter["impactLevel"] = GridFilterHelpers.textContains_Filter(level);
  }

  if (type !== null) {
    filter["impactTypeNames"] = GridFilterHelpers.textIncludes_Filter(type);
  }

  if (owner !== null) {
    filter["ownerName"] = GridFilterHelpers.textEquals_Filter(owner);
  }

  if (progressStatus) {
    const progressStatusesFromQuery = progressStatus.split("|");

    if (progressStatusesFromQuery?.length > 0) {
      filter["progressStatus"] = GridFilterHelpers.getMultiValueCondition_Filter(
        progressStatusesFromQuery,
        "equals",
        "OR"
      );
    } else {
      filter["progressStatus"] = GridFilterHelpers.textContains_Filter(progressStatus);
    }
  }

  if (createdBy !== null) {
    filter["createdByName"] = GridFilterHelpers.textEquals_Filter(createdBy);
  }
  if (reviewedBy !== null) {
    filter["reviewedByName"] = GridFilterHelpers.textEquals_Filter(reviewedBy);
  }

  return filter;
};
