import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react";
import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import { Button, ButtonIcon, ButtonTypes } from "../../../../../../../components/ui/Button";
import { IconSymbols } from "../../../../../../../components/ui/Icon";
import { Panel } from "../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { Input } from "../../../../../../../components/ui/_forms/Input";
import { CanEdit, CanView } from "../../../../../../../contexts/permissions/PermissionHelpers";
import { PermissionErrorMessage } from "../../../../../../../contexts/permissions/PermissionMessages";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../core/localization/I18n";
import { useModalContext } from "../../../../../../../core/modalZ/context/ModalContext";
import { Enums, StakeholderType } from "../../../../../../../enums";
import { ImpactStakeholderCard } from "../../../../../../change/stakeholders/impactStakeholderCard";
import { AddStakeholderForm } from "./AddStakeholderForm";
import { ImpactStakeholdersSidebarModel } from "./ImpactStakeholdersSidebar_model";

interface ImpactStakeholderSidebarProps {
  model: ImpactStakeholdersSidebarModel;
}

export const ImpactStakeholdersSidebar: React.FC<ImpactStakeholderSidebarProps> = observer(({ model }) => {
  const { impactId, projectId } = model;
  const [formIsActive, setFormIsActive] = useState(false);
  const isIndivStakeholders = model.stakeholderType === StakeholderType.INDIVIDUAL;
  const modalService = useModalContext();
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="impact-stakeholders pt-5">
      <CanView
        field={PermissionFields.STAKEHOLDERS}
        projectId={model.projectId}
        errorMessage={
          <div className="row mb-4">
            <div className="col">
              <PermissionErrorMessage>
                <p className="mb-0">{I18n.t("phrases.noImpactAccess")}</p>
              </PermissionErrorMessage>
            </div>
          </div>
        }
      ></CanView>
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col">
            <CanEdit field={PermissionFields.STAKEHOLDERS} projectId={projectId}>
              <Button
                onClick={() => {
                  setFormIsActive(true);
                }}
              >
                {isIndivStakeholders ? I18n.t("phrases.addStakeholder") : I18n.t("phrases.addAudience")}
              </Button>
              {formIsActive && (
                <AddStakeholderForm
                  setIsActive={setFormIsActive}
                  organisationId={model.organisationId}
                  projectId={model.projectId}
                  impactId={impactId}
                  reloadImpactFn={model.loadProjectStakeholders}
                  stakeholderType={model.stakeholderType}
                />
              )}
            </CanEdit>
            {!formIsActive && (
              <>
                <ButtonIcon
                  className="float-right"
                  symbol={model.isSearchMode ? IconSymbols.Close : IconSymbols.Search}
                  iconSize={Enums.UiSizes.SM}
                  type={ButtonTypes.OUTLINE_PRIMARY}
                  onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
                />
                <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
                  <Input
                    autoFocus={true}
                    size={-1}
                    onChange={model.updateSearchValue}
                    borderStyle="underline"
                    placeholder={I18n.t("placeholders.searchImpactStakeholders")}
                    icon={IconSymbols.Search}
                    value={model.searchValue}
                    onBlur={null}
                    className="mt-3"
                  />
                </AnimateHeight>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {model.filteredProjectStakeholders?.length ? (
              model.filteredProjectStakeholders.map(e => {
                let stakeholder = e.stakeholder;
                return (
                  <ImpactStakeholderCard
                    stakeholder={stakeholder}
                    pStakeholderId={e.id}
                    onLinkClickFn={model.closeModalFn}
                    projectId={projectId}
                    deleteFn={model.removeProjectStakeholder}
                    impactId={model.impactId}
                    projectStakeholder={e}
                    onRoleAdded={() => model.loadProjectStakeholders(impactId)}
                  />
                );
              })
            ) : (
              <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 mb-3 text-center">
                <div>
                  {isIndivStakeholders ? I18n.t("phrases.noImpactStakeholders") : I18n.t("phrases.noImpactAudiences")}
                </div>
              </Panel.Panel>
            )}
          </div>
        </div>
        <div className="row mb-5">
          <div className="col">
            <div className="d-flex">
              <Button
                className="ml-auto"
                onClick={() => {
                  modalService.hide();
                }}
              >
                {I18n.t("phrases.done")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
