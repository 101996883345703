import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { observer } from "mobx-react";
import React from "react";
import { Panel } from "../../../../../../../components/ui/Panel";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { Tag } from "../../../../../../../components/ui/Tag";
import PermissionsContext from "../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../../../../core/localization/I18n";
import { ImpactTagsSidebarModel } from "./ImpactTagsSidebar_model";
import { ManageImpactTagForm } from "./ManageImpactTagForm";

export interface ImpactTagsSidebarProps {
  model: ImpactTagsSidebarModel;
}

export const ImpactTagsSidebar: React.FC<ImpactTagsSidebarProps> = observer(({ model }) => {
  const { impact } = model;
  const canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, model.organisationId, model.projectId);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      {canEdit ? (
        <div className="row mb-4">
          <div className="col">
            <ManageImpactTagForm
              closeFn={model.closeModalFn}
              projectId={model.projectId}
              organisationId={model.organisationId}
              impact={model.impact}
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col">
            {impact.tags.length > 0 ? (
              impact.tags.map(t => (
                <Tag
                  tagText={t.text}
                  isEditable={false}
                  testId={t.id + ""}
                  key={t.id}
                  isNew={false}
                  onClick={e => {
                    model.removeTag(t.id);
                  }}
                />
              ))
            ) : (
              <div>
                <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                  <div>{I18n.t("phrases.noTags")}</div>
                </Panel.Panel>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
});
