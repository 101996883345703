import OktaAuth from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { ErrorBoundary } from "../../components/ui/ErrorBoundary";
import { UiPortalsProvider } from "../../components/ui/UiPortal";
import {
  FeatureToggleProvider,
  configFeatureProvider,
  isFeatureEnabledFactory,
  locationSearchFeatureProvider
} from "../../components/ui/featureToggle";
import { OrganisationSettingsContextProvider } from "../../contexts/organisationSettings/OrganisationSettingsContext";
import PermissionContext, { PermissionContextProvider } from "../../contexts/permissions/PermissionsContext";
import { HasOrganisationAccess } from "../../core/router/Route";
import { FlightPathThemeProvider } from "../../core/theme/themeProvider";
import { InsightRoutes } from "../../routes";
import { ToasterServiceView } from "../../core/toaster/ToasterService";
import { ProgressIndicator } from "../../components/widgets/ProgressIndicator/ProgressIndicator_view";
import { AgGridContextProvider } from "../../contexts/agGrid/agGridContext";

const isFeatureEnabled = isFeatureEnabledFactory(
  configFeatureProvider(window.appConfig),
  locationSearchFeatureProvider
);
export const InsightProviders: React.FC<
  { authService: OktaAuth; restoreOriginalUri: (_oktaAuth: any, originalUri: string) => void } & ReactProps
> = ({ authService, restoreOriginalUri }) => {
  // const [appModule] = useState(() => new ChangeApp());
  // const state = useAsync(async () => {
  //   let application = await bootstrap();
  //   await appModule.install();
  //   return application;
  // }, [appModule]);

  return (
    <FlightPathThemeProvider>
      <ToasterServiceView />
      <ProgressIndicator />
      <FeatureToggleProvider isFeatureEnabled={isFeatureEnabled}>
        <UiPortalsProvider>
          <ErrorBoundary>
            <Security oktaAuth={authService} restoreOriginalUri={restoreOriginalUri}>
              <PermissionContextProvider permissionContext={PermissionContext}>
                <OrganisationSettingsContextProvider>
                  <HasOrganisationAccess>
                    <AgGridContextProvider>
                      <InsightRoutes authService={authService} restoreOriginalUri={restoreOriginalUri} />
                    </AgGridContextProvider>
                  </HasOrganisationAccess>
                </OrganisationSettingsContextProvider>
              </PermissionContextProvider>
            </Security>
          </ErrorBoundary>
        </UiPortalsProvider>
      </FeatureToggleProvider>
    </FlightPathThemeProvider>
  );
};
