import { GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import { ButtonTypes } from "../../../../../components/ui/Button";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../core/toaster/ToasterService";
import { UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { ProjectStakeholderField, StakeholderType } from "../../../../../enums";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import GridToastService from "../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../services/local/gridToastService/IGridToastService";
import { GetStakeholderURLFilters } from "./StakeholdersView_filters";
import {
  SHOW_STAKEHOLDER_DELETE_CONFIRM_MODAL,
  SHOW_STAKEHOLDER_IMPACT_LINK_MODAL,
  SHOW_STAKEHOLDER_LINK_MODAL,
  SHOW_STAKEHOLDER_REVIEW_CONFIRM_MODAL
} from "./StakeholdersView_modal";
import Pages from "../../../../../routes/InsightRoutes";
import ColumnDefsApi from "../../../../../services/api/v2/columnDefs/ColumnDefs.api";
import { StakeholderGridViewModel } from "./StakeholderGridView/StakeholderGridView_model";
import { SHOW_STAKEHOLDER_ACTION_LINK_MODAL } from "../../ImpactsView/ImpactsView_modals";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../services/api/v2/actions/Actions.api";

export class StakeholdersViewModel extends BaseModel {
  projectId: number;
  organisationId: number;
  @observable isLoading: boolean = true;
  @observable stakeholderCount: number;
  @observable audienceCount: number;
  authUser: FP.Entities.IUser;
  httpProgress: IProgressIndicatorModel;
  toasterService: IToasterService;
  gridToastService: IGridToastService;
  gridApi: any;
  @observable.ref selectedStakeholderIds: number[] = [];
  selectedStakeholders: FP.Entities.IProjectStakeholderSummary[] = [];
  projectStakeholderProvider: IProjectStakeholdersApi;
  modalService: IModalContextModel;
  navigate: any;
  filterModel?: any;
  @observable searchText: string;
  @observable.ref columnDefs: any[];
  gridModel: StakeholderGridViewModel;
  actions = [
    {
      id: "action1",
      label: I18n.t("entities.impacts"),
      onAction: ev => {
        this.showLinkToImpact();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action2",
      label: I18n.t("entities.actionOwned"),
      onAction: ev => {
        this.showLinkToActionOwnersModal();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];
  actionsProvider: IActionsApi;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser, navigate: any) {
    super();
    makeObservable(this);
    this.projectId = projectId;
    this.organisationId = organisationId;
    this.authUser = authUser;
    this.toasterService = ToasterService;
    this.modalService = ModalContext;
    this.gridToastService = GridToastService;
    this.actionsProvider = ActionsApi;
    this.projectStakeholderProvider = ProjectStakeholdersApi;
    this.httpProgress = ProgressIndicatorModel;
    this.navigate = navigate;
  }

  onMount = async () => {
    await this.loadColumnDefs();
    await this.loadStakeholderAndAudienceCounts();
  };

  onUnmount = () => {};

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  loadColumnDefs = async () => {
    let res = await ColumnDefsApi.getStakeholderColumnDefs(this.organisationId);
    this.setColumnDefs(res.payload);
    this.gridModel = new StakeholderGridViewModel(this.organisationId, this.projectId, this.authUser, res.payload);
    this.setIsLoading(false);
  };

  @action
  setColumnDefs = (columnDefs: any[]) => (this.columnDefs = columnDefs);

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.filterModel = {
      ...GetStakeholderURLFilters()
    };
    this.gridApi = gridReadyEvent.api;
    this.gridApi.setFilterModel({});

    setTimeout(() => {
      this.gridApi.setFilterModel(this.filterModel);
    });
  };

  updateSelectedStakeholderIds = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedStakeholderIds = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
    this.selectedStakeholders = _.map(event.api.getSelectedNodes(), e => {
      return e.data;
    });
  };

  @action
  deleteFieldData = async (stakeholderId: number, actionField: ProjectStakeholderField) => {
    const res = await this.projectStakeholderProvider.deleteField(
      this.organisationId,
      this.projectId,
      stakeholderId,
      actionField
    );
    if (res.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.navigate(
        Pages.projects.stakeholders.visualisations.parameters.generateLink(this.organisationId, this.projectId, 1, 0)
      );
      return;
    }
    if (newTabIndex === 0) {
      this.navigate(Pages.projects.stakeholders.listView.generateLink(this.organisationId, this.projectId));
      return;
    }
    this.navigate(Pages.projects.audiences.listView.generateLink(this.organisationId, this.projectId));
  };

  showConfirmReviewModal = () => {
    return SHOW_STAKEHOLDER_REVIEW_CONFIRM_MODAL(
      this.modalService,
      this.selectedStakeholderIds,
      this.reviewStakeholderRange,
      this.toasterService,
      this.deselectRows
    );
  };

  @action
  deselectRows = () => {
    if (this.gridApi !== undefined) this.gridApi.deselectAll();
  };

  showStakeholderConfirmDeleteModal = () => {
    return SHOW_STAKEHOLDER_DELETE_CONFIRM_MODAL(
      this.modalService,
      this.selectedStakeholderIds,
      this.removeStakeholders
    );
  };

  showLinkStakeholderModal = () => {
    return SHOW_STAKEHOLDER_LINK_MODAL(this.modalService, this.projectId, StakeholderType.INDIVIDUAL);
  };

  showLinkToImpact = () => {
    return SHOW_STAKEHOLDER_IMPACT_LINK_MODAL(this.modalService, this.projectId, this.selectedStakeholderIds);
  };

  showLinkToActionOwnersModal = () => {
    return SHOW_STAKEHOLDER_ACTION_LINK_MODAL(
      this.modalService,
      this.projectId,
      this.assignActionProjectStakeholderOwners
    );
  };

  removeStakeholders = async () => {
    this.httpProgress.showTopProgressBarVisible();
    let res = await this.projectStakeholderProvider.deleteRange(
      this.organisationId,
      this.projectId,
      this.selectedStakeholderIds
    );
    this.httpProgress.hideTopProgressBarVisible();
    this.loadStakeholderAndAudienceCounts();
    if (!res || res.isError) return;
    return res.payload;
  };

  reviewStakeholderRange = async (projectStakeholderIds: number[], comment: string) => {
    let res = await this.projectStakeholderProvider.reviewRange(
      this.organisationId,
      this.projectId,
      projectStakeholderIds,
      comment
    );

    if (!res || res.isError) return;
  };

  loadStakeholderAndAudienceCounts = async () => {
    var res = await this.projectStakeholderProvider.getStakeholderAndAudienceCountsForProject(
      this.organisationId,
      this.projectId
    );

    if (!res || res.isError) return;
    if (res.payload) {
      this.setStakeholderCounts(res.payload.stakeholderCount, res.payload.audienceCount);
    }
  };

  @action
  setStakeholderCounts = (stakeholderCount, audienceCount) => {
    this.stakeholderCount = stakeholderCount;
    this.audienceCount = audienceCount;
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-stakeholders-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedStakeholderIds && this.selectedStakeholderIds.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };

  assignActionProjectStakeholderOwners = async (actionIds: number[]) => {
    await this.actionsProvider.assignStakeholderOwners(
      this.organisationId,
      this.projectId,
      actionIds,
      this.selectedStakeholderIds
    );
    this.modalService.hide();
  };
  // @action
  // onGridReady(gridReadyEvent: GridReadyEvent): void {

  //   this.filterModel = {
  //     ...apiFilters.getFilters()
  //   };
  //   this.gridApi = gridReadyEvent.api;
  //   this.gridApi.setFilterModel({});

  //   setTimeout(() => {
  //     this.gridApi.setFilterModel(this.filterModel);
  //   });
  // }
}
