import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../BaseApiModel";
import http from "../Http";
import { ExportableProjectApi } from "../exportableApi/ExportableProjectApiModel";

export class ProjectProcessesApi extends ExportableProjectApi<any> {
  controller: string = "project-processes";

  constructor(http: AxiosInstance) {
    super(http, "project-processes");
  }

  getImpactGroups = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpactGroup[]>> => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/impact-groups`,
      config
    );
    return res.data;
  };

  removeImpactGroup = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/impact-groups/${impactGroupId}`,
      config
    );
    return res.data;
  };

  addImpactGroup = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    impactGroupId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/impact-groups/${impactGroupId}`,
      config
    );
    return res.data;
  };

  addMultipleImpactGroups = async (
    organisationId: number,
    projectId: number,
    impactGroupIds: number[],
    projectProcessIds: number[],
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/add-multiple-impact-groups`;
    let res = await this.http.post(url, { impactGroupIds, projectProcessIds }, config);
    return res.data;
  };

  getProcessApps = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProcessApp[]>> => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/process-apps`,
      config
    );
    return res.data;
  };

  addProcessApp = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    processAppId: number,
    name: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/process-apps/${processAppId}`,
      { name },
      config
    );
    return res.data;
  };

  removeProcessApp = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    processAppId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/process-apps/${processAppId}`,
      config
    );
    return res.data;
  };

  getProcessRoles = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProcessRole[]>> => {
    let res = await this.http.get(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/process-roles`,
      config
    );
    return res.data;
  };

  addProcessRole = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    processRoleId: number,
    name: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/process-roles/${processRoleId}`,
      { name },
      config
    );
    return res.data;
  };

  removeProcessRole = async (
    organisationId: number,
    projectId: number,
    projectProcessId: number,
    processRoleId: number,
    config?: AxiosRequestConfig
  ) => {
    let res = await this.http.delete(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/${projectProcessId}/process-roles/${processRoleId}`,
      config
    );
    return res.data;
  };

  deleteRange = async (organisationId: number, projectId: number, ids: number[], config?: AxiosRequestConfig) => {
    let res = await this.http.post(
      `${this.url}/${organisationId}/projects/${projectId}/${this.controller}/delete-range`,
      ids,
      config
    );
    return res.data;
  };
}

const instance = new ProjectProcessesApi(http);
export default instance;
