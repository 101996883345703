import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../services/api/v2/impacts/Impacts.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../../../../services/api/v2/projects/Projects.api";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import { getImpactMilestonesFormFields } from "./ImpactMilestonesSidebar_fields";
import I18n from "../../../../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../../../../core/uiAction/IUiAction";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";

export class ImpactMilestonesSidebarModel extends BaseModel {
  impactsProvider: IImpactsApi;
  projectsProvider: IProjectsApi;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;
  formModel: SingleFormModel;

  constructor(closeModalFn: () => void, organisationId: number, projectId: number, impactId: number) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = ImpactsApi;
    this.isLoading = true;
    this.projectsProvider = ProjectsApi;
    this.loadImpact(impactId);
    this.closeModalFn = closeModalFn;
  }

  setupFormModel = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactMilestonesFormFields(
      this.impactsProvider,
      this.projectsProvider,
      this.organisationId,
      this.projectId,
      this.impact
    );
    this.formModel.actions = [
      {
        id: "action1",
        label: I18n.t("phrases.done"),
        onAction: ev => ModalContext.hide(),
        componentProps: {
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
  };

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
    this.setupFormModel();
    this.isLoading = false;
  };

  @action
  loadImpact = async (impactId: number) => {
    let res = await this.impactsProvider.getDetailedById(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;

    this.setImpact(res.payload);
  };
}
