import * as React from "react";
import { observer } from "mobx-react";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import I18n from "../../../../../core/localization/I18n";
import { CommentListView } from "../../../comments/commentListView/CommentListView_view";
import { Async } from "react-async";
import { CommentListViewModel } from "../../../comments/commentListView/CommentListView_model";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import ImpactGroupsApi from "../../../../../services/api/v2/impactGroups/ImpactGroups.api";

export interface model {
  projectId: number;
  impactGroupId: number;
  organisationId: number;
  authUser: FP.Entities.IUser;
}

export const ImpactGroupNotesSideBar: React.FunctionComponent<model> = observer(p => {
  let [impactGroup, setImpactGroup] = React.useState(null);
  const authUser = p.authUser;
  const projectId = p.projectId;
  const impactGroupId = p.impactGroupId;
  const organisationId = p.organisationId;
  const impactGroupProvider = ImpactGroupsApi;

  const listViewConfig = React.useMemo(() => {
    return {
      searchAttribute: "impactGroupId",
      id: -1,
      title: null,
      projectId: projectId,
      organisationId: organisationId,
      placeholderText: I18n.t("placeholders.highLevelImpactNotePlaceholder")
    };
  }, [projectId, organisationId]);

  const commentViewModel = React.useMemo(
    () => new CommentListViewModel(projectId, authUser, listViewConfig),
    [projectId, authUser, listViewConfig]
  );

  const load = React.useCallback(async () => {
    const res = await impactGroupProvider.getDetailed(organisationId, projectId, impactGroupId);

    if (!res || res.isError) {
      return;
    }

    setImpactGroup(res.payload);

    commentViewModel.setConfig({
      id: impactGroupId
    });
  }, [impactGroupId, projectId, organisationId, commentViewModel, impactGroupProvider]);

  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="container-fluid impact-compact-view">
            <div className="row mb-6">
              <div className="col">
                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="mb-0">{impactGroup.name}</h1>
                </div>
                <h3 className="text-uppercase mb-0">{impactGroup.refNumber}</h3>
              </div>
            </div>
            <div className="row mb-0">
              <div className="col">
                <h3 className="mb-0 pl-0 pb-0">{I18n.t("phrases.addANote")}</h3>
              </div>
            </div>
            <CommentListView model={commentViewModel} field={PermissionFields.IMPACTS} />
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});
