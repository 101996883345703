import { observer } from "mobx-react";
import React from "react";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { ImpactLocationsSidebarModel } from "./ImpactLocationsSidebar_model";

export interface ImpactLocationsSidebarProps {
  model: ImpactLocationsSidebarModel;
}

export const ImpactLocationsSidebar: React.FC<ImpactLocationsSidebarProps> = observer(({ model }) => {
  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="container-fluid">
      <SingleForm model={model.formModel} />
    </div>
  );
});
