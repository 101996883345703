import { ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Panel } from "../../../../components/ui/Panel";
import { ReviewModalComponentProps, ReviewModalContent, ReviewModalTitle } from "../../../../components/ui/ReviewModal";
import {
  GridSideModalTitle,
  gridSideModalAnimationOpts,
  gridSideModalComponentProps
} from "../../../../core/grids/GridSideModals";
import I18n from "../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { Animations } from "../../../../core/util/Animations";
import { ImpactsUploader } from "../ImpactsUploader/ImpactsUploader_view";
import { ActionGrid } from "../../../../core/grids/commonGrids/ActionGrid/ActionGrid_view";
import { StakeholderEmail } from "./modals/StakeholderEmail/StakeholderEmail_view";
import { StakeholderGrid } from "../../../../core/grids/commonGrids/StakeholderGrid/StakeholderGrid_view";
import { BulkUpdateImpactsSidebar } from "./modals/ImpactBulkUpdateSidebar/impactBulkUpdateSidebar_view";
import { ImpactBulkUpdateSidebarModel } from "./modals/ImpactBulkUpdateSidebar/impactBulkUpdateSidebar_model";
import { ImpactLocationsSidebar } from "./modals/impactLocationsSidebar/ImpactLocationsSidebar_view";
import { ImpactBusinessAreasSidebar } from "./modals/impactBusinessAreasSidebar/ImpactBusinessAreasSidebar_view";
import { ImpactGroupGrid } from "../../../../core/grids/commonGrids/ImpactGroupGrid/ImpactGroupGrid_view";
import { LabelGrid } from "../../../../core/grids/commonGrids/LabelGrid/LabelGrid_view";

export const SHOW_IMPACT_DELETE_CONFIRM_MODAL = (
  modalService: IModalContextModel,
  impactIds: number[],
  removeImpactRange: (impactIds: number[]) => Promise<any>
) => {
  return new Promise(resolve => {
    modalService.showConfirmDialog(
      <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12">
            <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
            {I18n.t("warnings.removeImpactFromProject")}
          </div>
        </div>
        <div className="row">
          <div className="col">{I18n.t("phrases.confirmImpactRangeRemove", { name: impactIds.length + "" })}</div>
        </div>
      </div>,
      I18n.t("phrases.yes"),
      I18n.t("phrases.no"),
      {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      async () => {
        modalService.hide();
        await removeImpactRange(impactIds);
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.DANGER
    );
  });
};

export const SHOW_IMPACT_REVIEW_CONFIRM_MODAL = (
  modalService: IModalContextModel,
  impactIds: number[],
  reviewImpactRange: (impactIds: number[], comment: string) => Promise<any>,
  toastService: IToasterService,
  onCloseFn: () => void
) => {
  return new Promise(resolve => {
    let comment = "";
    const handleInputChange = ss => {
      comment = ss;
    };
    modalService.showConfirmDialog(
      <ReviewModalTitle />,
      <ReviewModalContent reviewCommentInput={comment} handler={handleInputChange} />,
      I18n.t("phrases.confirmReview"),
      I18n.t("phrases.cancelReview"),
      ReviewModalComponentProps,
      async () => {
        await reviewImpactRange(impactIds, comment);
        modalService.hide();
        toastService
          .showSuccessToast()
          .setContent(<span>{I18n.t("phrases.reviewCompleted")}</span>)
          .startTimer(TOASTER_TOAST_TIME.NORMAL);
        onCloseFn();
        resolve(true);
      },
      () => {
        modalService.hide();
      },
      ButtonTypes.PRIMARY,
      ButtonTypes.OUTLINE_PRIMARY
    );
  });
};

export const SHOW_STAKEHOLDER_IMPACT_LINK_MODAL = (
  modalService: IModalContextModel,
  projectId: number,
  onAssignSuccess: (stakeholderIds: number[]) => Promise<any>
) => {
  modalService.show({
    showClose: false,
    title: null,
    content: <StakeholderGrid projectId={projectId} onAssignSuccess={onAssignSuccess} />,
    componentProps: {
      ...gridSideModalComponentProps,
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle",
      className: "modal__grid-link"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_STAKEHOLDER_ACTION_LINK_MODAL = (
  modalService: IModalContextModel,
  projectId: number,
  assignActionsToImpacts: (actionIds: number[]) => void
) => {
  modalService.show({
    showClose: false,
    title: null,
    content: <ActionGrid projectId={projectId} onAssignSuccess={assignActionsToImpacts} />,
    componentProps: {
      ...gridSideModalComponentProps,
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle",
      className: "modal__grid-link"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_IMPACT_BULK_EDIT_MODAL = (
  modalService: IModalContextModel,
  projectId: number,
  organisationId: number,
  selectedImpacts: number[]
) => {
  modalService.show({
    showClose: true,
    title: (
      <GridSideModalTitle
        name={I18n.t("phrases.updatingInBulk")}
        field={`${I18n.t("phrases.noOfImpactsSelected", {
          plural: selectedImpacts.length > 1 ? "s" : "",
          num: selectedImpacts.length
        })}`}
      />
    ),
    content: (
      <BulkUpdateImpactsSidebar
        model={new ImpactBulkUpdateSidebarModel(modalService.hide, projectId, organisationId, selectedImpacts)}
      ></BulkUpdateImpactsSidebar>
    ),
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};

export const SHOW_LINK_TO_LOCATIONS = (
  modalService: IModalContextModel,
  assignLocationsToImpacts: (locationIds: number[]) => void
) => {
  modalService.show({
    showClose: true,
    title: (
      <div className="mt-6">
        <h3 className="mb-5">Assign locations to selected impacts.</h3>
      </div>
    ),
    content: <ImpactLocationsSidebar onSubmit={assignLocationsToImpacts} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};

export const SHOW_LINK_TO_BUSINESS_AREAS = (
  modalService: IModalContextModel,
  assignBusinessAreasToImpacts: (businessAreaIds: number[]) => void
) => {
  modalService.show({
    showClose: true,
    title: (
      <div className="mt-6">
        <h3 className="mb-5">Assign business areas to selected impacts.</h3>
      </div>
    ),
    content: <ImpactBusinessAreasSidebar onSubmit={assignBusinessAreasToImpacts} />,
    componentProps: gridSideModalComponentProps,
    animationOptions: gridSideModalAnimationOpts
  });
};

export const SHOW_LINK_TO_IMPACT_GROUPS = (
  modalService: IModalContextModel,
  projectId: number,
  assignImpactGroupsToImpacts: (impactGroupIds: number[]) => void
) => {
  modalService.show({
    showClose: false,
    title: null,
    content: <ImpactGroupGrid onAssignSuccess={assignImpactGroupsToImpacts} projectId={projectId} />,
    componentProps: {
      ...gridSideModalComponentProps,
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle",
      className: "modal__grid-link"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_LABELS_LINK_MODAL = (
  modalService: IModalContextModel,
  projectId: number,
  onAssignSuccess: (labelIds: number[]) => Promise<any>
) => {
  modalService.show({
    showClose: false,
    title: null,
    content: <LabelGrid projectId={projectId} onAssignSuccess={onAssignSuccess} />,
    componentProps: {
      ...gridSideModalComponentProps,
      wrapHeight: "default",
      wrapWidth: "large",
      position: "middle",
      className: "modal__grid-link"
    },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_STAKEHOLDER_EMAILS = (modalService: IModalContextModel, impactIds: number[], projectId: number) => {
  modalService.show({
    showClose: true,
    title: null,
    content: <StakeholderEmail impactIds={impactIds} projectId={projectId} />,
    componentProps: { ...gridSideModalComponentProps, wrapHeight: "default", wrapWidth: "large", position: "middle" },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};

export const SHOW_IMPACT_UPLOADER_MODAL = (modalService: IModalContextModel) => {
  modalService.show({
    showClose: true,
    title: <h1 className="mt-3 px-3">Upload impacts</h1>,
    content: (
      <>
        <img
          className="beta-ribbon-img"
          src="/images/assets/beta-ribbon.png"
          alt=""
          style={{
            height: 50,
            width: 50,
            position: "absolute",
            top: 0,
            left: 0
          }}
        />
        <ImpactsUploader />
      </>
    ),
    componentProps: { ...gridSideModalComponentProps, wrapHeight: "default", wrapWidth: "large", position: "middle" },
    animationOptions: {
      animateIn: Animations.SLIDE_IN_UP,
      animateOut: Animations.SLIDE_OUT_DOWN
    }
  });
};
