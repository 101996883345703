import { useEffect, useState } from "react";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../../../core/auth_insight";
import { ActivityProcessTemplateSelectionModel } from "./ActivityProcessTemplateSelection_model";
import { ActivityProcessTemplateSelectionGridViewModel } from "./ActivityProcessTemplateSelectionGrid_model";
import { ActivityProcessTemplateSelectionView } from "./ActivityProcessTemplateSelectionView/ActivityProcessTemplateSelectionView_view";
import { L1ProcessSelectionView } from "./L1ProcessSelectionView/L1ProcessSelectionView_view";
import { L3ProcessSelectionGridView } from "./L3ProcessSelectionGridView/L3ProcessSelectionGridView_view";
import { observer } from "mobx-react";
import { ActivityProcessTemplates } from "../../ActivityProcessesTemplate";

export interface IActivityProcessTemplateSelectionProps {
  projectId: number;
}

export const ActivityProcessTemplateSelection: React.FC<ReactProps & IActivityProcessTemplateSelectionProps> = observer(
  props => {
    const { projectId } = props;
    const organisationId = useCurrentOrganisationId();
    const authUser = useFlightPathUser();
    const [model, setModel] = useState(
      () => new ActivityProcessTemplateSelectionModel(organisationId, projectId, authUser, null)
    );
    const [gridModel] = useState(
      () => new ActivityProcessTemplateSelectionGridViewModel(organisationId, projectId, authUser)
    );
    const templates = ActivityProcessTemplates;
    const [currStep, setCurrStep] = useState(0);
    const [templateId, setTemplateId] = useState(null);

    useEffect(() => {
      setModel(new ActivityProcessTemplateSelectionModel(organisationId, projectId, authUser, templates[templateId]));
      //eslint-disable-next-line
    }, [templateId]);

    const handleTemplateSelection = (selectedTemplateId: number) => {
      setTemplateId(selectedTemplateId);
      setCurrStep(currStep + 1);
    };

    const handleL1Complete = (selectedL1Processes: any) => {
      setCurrStep(currStep + 1);
      model.onMount(selectedL1Processes);

      model.onUnmount();
    };

    const handlePreviousStep = () => {
      if (currStep > 0) {
        setCurrStep(currStep - 1);
      }
    };

    return (
      <div className="container-fluid">
        {currStep === 0 && (
          <ActivityProcessTemplateSelectionView
            handleNextStep={handleTemplateSelection}
            templates={templates}
          ></ActivityProcessTemplateSelectionView>
        )}
        {currStep === 1 && (
          <L1ProcessSelectionView
            templateProcesses={templates[templateId].processes}
            handleNextStep={handleL1Complete}
            handlePreviousStep={handlePreviousStep}
          ></L1ProcessSelectionView>
        )}
        {currStep === 2 && (
          <L3ProcessSelectionGridView
            model={model}
            gridModel={gridModel}
            handlePreviousStep={handlePreviousStep}
            templateProcesses={templates[templateId].processes}
          ></L3ProcessSelectionGridView>
        )}
      </div>
    );
  }
);
