import { action, makeObservable, observable } from "mobx";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../services/api/v2/impacts/Impacts.api";

export class StakeholderEmailModel {
  impactIds: number[];
  impactsProvider: IImpactsApi;
  @observable stakeholders: any = [];
  @observable isLoading: boolean = true;
  organisationId: number;
  projectId: number;

  constructor(organisationId: number, projectId: number, impactIds: number[]) {
    makeObservable(this);
    this.impactIds = impactIds;
    this.impactsProvider = ImpactsApi;
    this.projectId = projectId;
    this.organisationId = organisationId;
  }

  onMount = async () => {
    await this.loadImpacts();
  };

  loadImpacts = async () => {
    let res = await this.impactsProvider.getStakeholderEmailsByImpactIds(
      this.organisationId,
      this.projectId,
      this.impactIds
    );
    if (!res || res.isError) return;
    this.setStakeholders(res.payload);
    this.setIsLoading(false);
  };

  @action
  setStakeholders = stakeholders => {
    this.stakeholders = stakeholders;
  };

  @action
  setIsLoading = isLoading => {
    this.isLoading = isLoading;
  };
}
