import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import React from "react";
import { ButtonTypes } from "../../../../../components/ui/Button";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../core/toaster/Toaster_model";
import { IUiAction, UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { ActionField, Enums } from "../../../../../enums";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../services/api/v2/actions/Actions.api";
import CommentsApi, { CommentsApi as ICommentsApi } from "../../../../../services/api/v2/comments/Comments.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../../services/api/v2/projects/Projects.api";
import GridToastService from "../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../services/local/gridToastService/IGridToastService";
import { ILocalStorageService } from "../../../../../services/local/localStorageService/ILocalStorageService";
import LocalStorageService from "../../../../../services/local/localStorageService/LocalStorageService";
import { getEntityNameMicroFormFields } from "../../../../change/forms/microForm/getEntityNameMicroFormFields";
import { SingleFormModel } from "../../../../change/forms/singleFormModel/SingleForm_model";
import { getActionGridFilters } from "./AcGridView/AcGridView_apiFilter";
import { AcGridViewModel } from "./AcGridView/AcGridView_model";
import {
  SHOW_ACTION_DELETE_CONFIRM_MODAL,
  SHOW_ACTION_REVIEW_CONFIRM_MODAL,
  SHOW_CONFIRM_CREATION_MODAL,
  SHOW_NOTES_MODAL,
  SHOW_IMPACT_LINK_MODAL,
  SHOW_ACTIONS_UPLOADER_MODAL,
  SHOW_ACTION_BULK_EDIT_MODAL,
  SHOW_LABELS_LINK_MODAL
} from "./ActionListView_modals";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import { SHOW_STAKEHOLDER_IMPACT_LINK_MODAL } from "../../ImpactsView/ImpactsView_modals";

export class ActionListViewModel extends BaseModel {
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  navigate: any;
  organisationId: number;
  toasterService: IToasterService;
  modalService: IModalContextModel;
  gridToastService: IGridToastService;
  projectProvider: IProjectsApi;
  actionProvider: IActionsApi;
  httpProgress: IProgressIndicatorModel;
  projectId: number;
  @observable.ref project: FP.Entities.IProject;
  @observable.ref actions: FP.Entities.IAction[];
  @observable.ref selectedActions: number[] = [];
  @observable actionCount: number = 0;
  hasBeenReviewed: boolean;
  reviewCommentInput: string;
  @observable topActionBarActions: IUiAction<any>[] = [];
  authUser: FP.Entities.IUser;
  microActionForm: SingleFormModel;
  commentsProvider: ICommentsApi;
  gridApi: GridApi;
  filterModel: any;
  storageService: ILocalStorageService;
  @observable gridModel: AcGridViewModel;
  @observable searchText: string;
  uiActions = [
    {
      id: "action1",
      label: I18n.t("entities.impacts"),
      onAction: ev => {
        this.showLinkActionsToImpactModal();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action2",
      label: I18n.t("entities.stakeholderOwners"),
      onAction: ev => {
        this.showLinkStakeholdersToActionsModal();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: "text-left"
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action3",
      label: I18n.t("entities.tags"),
      onAction: ev => {
        this.showLinkLabelsToActionsModal();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  constructor(navigate: any, organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    super();
    makeObservable(this);
    this.projectTeamUserPermissionsProvider = ProjectTeamUserPermissionsApi;
    this.navigate = navigate;
    this.organisationId = organisationId;
    this.toasterService = ToasterService;
    this.modalService = ModalContext;
    this.projectProvider = ProjectsApi;
    this.actionProvider = ActionsApi;
    this.httpProgress = ProgressIndicatorModel;
    this.commentsProvider = CommentsApi;
    this.gridToastService = GridToastService;
    this.authUser = authUser;
    this.storageService = LocalStorageService;
    this.gridModel = new AcGridViewModel(organisationId, +projectId, authUser, []);

    if (projectId) {
      this.projectId = projectId;
    }

    this.hasBeenReviewed = false;
    this.reviewCommentInput = "";
    this.setMicroActionForm();
  }

  onMount = async () => {
    this.setGridModel(new AcGridViewModel(this.organisationId, +this.projectId, this.authUser, []));
    await this.gridModel.onMount();
  };

  onUnmount = () => {};

  @action
  setActionCount = count => {
    this.actionCount = count;
  };

  @action
  setGridModel = (gridModel: AcGridViewModel) => {
    this.gridModel = gridModel;
  };

  @action
  updateSelectedActions = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedActions = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  @action
  setMicroActionForm = () => {
    this.microActionForm = new SingleFormModel();
    this.microActionForm.formFields = getEntityNameMicroFormFields(
      this.createMicroAction,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.action") })
    );
  };

  @action
  deselectRows = () => {
    if (this.gridApi !== undefined) this.gridApi.deselectAll();
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.gridApi = gridReadyEvent.api;
    this.filterModel = getActionGridFilters();
    this.gridApi.setFilterModel({});

    setTimeout(() => {
      this.gridApi.setFilterModel(this.filterModel);
    });
  };

  @action
  deleteFieldData = async (impactId: number, actionField: ActionField) => {
    const res = await this.actionProvider.deleteField(this.organisationId, this.projectId, impactId, actionField);
    if (res.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  @action
  createMicroAction = async () => {
    let microActionFormRes = await this.microActionForm.submit();
    this.microActionForm.isSaving = true;
    if (!microActionFormRes) return;

    microActionFormRes = { ...microActionFormRes, projectId: this.projectId, impacts: [], tags: [] };

    this.httpProgress.showOverlay();
    let actionNameExists = await this.actionProvider.getFiltered(this.organisationId, this.projectId, {
      filters: `name==${microActionFormRes.name},lifecycleStatus==${Enums.LifecycleStatus.Active},projectId=${this.projectId}`
    });

    if (actionNameExists && !actionNameExists.isError && actionNameExists.payload.length) {
      this.httpProgress.hideOverlay();
      let confirmCreateAction = await this.confirmCreateAction(microActionFormRes.name);
      if (!confirmCreateAction) return;
      this.httpProgress.showOverlay();
    }

    const res = {
      ...microActionFormRes
    };
    this.httpProgress.showOverlay();
    const result = await this.actionProvider.create(this.organisationId, this.projectId, res as FP.Entities.IAction);
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    const action = result.payload;
    this.microActionForm.resetFields();
    this.toasterService
      .showSuccessToast()
      .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.action") })}</span>)
      .startTimer(TOASTER_TOAST_TIME.NORMAL);

    return action;
  };

  confirmCreateAction = async (name: string): Promise<boolean> => {
    return SHOW_CONFIRM_CREATION_MODAL(this.modalService, name);
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  removeAction = async (actionIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.actionProvider.deleteRange(this.organisationId, this.projectId, actionIds);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  reviewActionRange = async (actionIds: number[], comment: string) => {
    let res = await this.actionProvider.reviewRange(this.organisationId, this.projectId, actionIds, comment);

    if (!res || res.isError) return;
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 1) {
      this.navigate(`/organisations/${this.organisationId}/projects/${this.projectId}/actions/visualisations`);
      return;
    }
    this.navigate(`/organisations/${this.organisationId}/projects/${this.projectId}/actions`);
  };

  showActionConfirmReviewModal = () => {
    return SHOW_ACTION_REVIEW_CONFIRM_MODAL(
      this.modalService,
      this.selectedActions,
      this.reviewActionRange,
      this.toasterService,
      this.deselectRows
    );
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-actions-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedActions && this.selectedActions.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  showNotesModal = row => {
    const id = row.id;
    SHOW_NOTES_MODAL(this.modalService, this.organisationId, this.projectId, id);
  };

  showActionConfirmDeleteModal = () => {
    return SHOW_ACTION_DELETE_CONFIRM_MODAL(this.modalService, this.selectedActions, this.removeAction);
  };

  showLinkActionsToImpactModal = () => {
    return SHOW_IMPACT_LINK_MODAL(this.modalService, this.projectId, this.selectedActions);
  };

  showLinkStakeholdersToActionsModal = () => {
    return SHOW_STAKEHOLDER_IMPACT_LINK_MODAL(this.modalService, this.projectId, this.assignStakeholdersToActions);
  };
  showLinkLabelsToActionsModal = () => {
    return SHOW_LABELS_LINK_MODAL(this.modalService, this.projectId, this.assignLabelsToActions);
  };

  showUploaderModel = () => {
    SHOW_ACTIONS_UPLOADER_MODAL(this.modalService);
  };

  showBulkEditModal = () => {
    return SHOW_ACTION_BULK_EDIT_MODAL(this.modalService, this.projectId, this.organisationId, this.selectedActions);
  };

  @action
  resetReviewAndSidebar = () => {
    this.hasBeenReviewed = false;
    this.modalService.hide();
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };

  assignStakeholdersToActions = async (stakeholderIds: number[]) => {
    await this.actionProvider.assignStakeholderOwners(
      this.organisationId,
      this.projectId,
      this.selectedActions,
      stakeholderIds
    );
    this.modalService.hide();
  };

  assignLabelsToActions = async (labelIds: number[]) => {
    await this.actionProvider.assignLabels(this.organisationId, this.projectId, this.selectedActions, labelIds);
    this.modalService.hide();
  };
}
