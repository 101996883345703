import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../../../../services/api/v2/impactGroups/ImpactGroups.api";
import ActivityProcessesApi, {
  ActivityProcessesApi as IActivityProcessesApi
} from "../../../../../../../services/api/v2/activityProcesses/ActivityProcesses.api";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";

export class ImpactGroupGridModel {
  projectId: number;
  impactGroupProvider: IImpactGroupsApi;
  activityProcessProvider: IActivityProcessesApi;
  organisationId: number;
  @observable isLoading: boolean = true;
  @observable gridImpactGroups: FP.Entities.IImpactSummary[];
  authUser: FP.Entities.IUser;
  @observable.ref selectedImpactGroups: number[] = [];
  gridApi: GridApi;
  @observable selectedActivityProcessIds: number[];
  searchText: string;
  projectProcessesProvider: IProjectProcessesApi;

  /**
   *
   */
  constructor(
    projectId: number,
    organisationId: number,
    authUser: FP.Entities.IUser,
    selectedActivityProcessIds: number[]
  ) {
    makeObservable(this);
    this.projectId = projectId;
    this.projectProcessesProvider = ProjectProcessesApi;
    this.impactGroupProvider = ImpactGroupsApi;
    this.activityProcessProvider = ActivityProcessesApi;
    this.organisationId = organisationId;
    this.selectedActivityProcessIds = selectedActivityProcessIds;
    this.authUser = authUser;
  }

  onMount = async () => {
    const res = await this.impactGroupProvider.getFiltered(this.organisationId, this.projectId, {});

    if (!res || res.isError) return;

    this.setGridImpactGoups(res.payload);
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setImpactGroupRowData = impactGroups => {
    this.setGridImpactGoups(impactGroups);
    this.isLoading = false;
  };

  @action
  setGridImpactGoups = impactGroups => {
    this.gridImpactGroups = impactGroups;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent) => {
    this.gridApi = gridReadyEvent.api;
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };

  @action
  updateSelectedImpactGroups = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedImpactGroups = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  assignImpactGroups = async () => {
    return await this.projectProcessesProvider.addMultipleImpactGroups(
      this.organisationId,
      this.projectId,
      this.selectedImpactGroups,
      this.selectedActivityProcessIds
    );
  };
}
