import { ColDef } from "ag-grid-community";
import { Enums, ImpactField, Translator } from "../../../../../enums";
import AgGridAutocomplete from "../../../../../core/grids/editors/AgGridAutocomplete";
import { baseColumn, baseDropdownColumn, baseTextColumn } from "../../../../../core/grids/BaseColumns";
import { GridPills } from "../../../../../components/widgets/gridPills/GridPills";
import { makeCellDeletable, openModalOnDelete } from "../../../../../core/grids/GridHelpers";
import { multiValueTextMatcher, zeroToTenTextMatcher } from "../../../../../core/grids/filters/TextMatcher";
import I18n from "../../../../../core/localization/I18n";

export const colImpactTypes = (canEdit: boolean, editFn?) => {
  let res: ColDef = {
    ...baseTextColumn({ fieldName: "impactTypeNames", heading: I18n.t("grids.impactTypes"), clickEditable: true }),
    editable: false,
    autoHeight: true,
    width: 500,
    cellRenderer: e => {
      const data = e.data?.impactTypeNames || e.getValue();

      if (data !== null && data?.length > 0) {
        return <GridPills text={data} />;
      }
      return (
        <span>
          {(e.column?.colId?.includes(e.node?.rowGroupColumn?.colId) && I18n.t("phrases.notAvailable")) || ""}
        </span>
      );
    },
    enableRowGroup: true
  };
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }
  return res;
};

export const colTotalPeopleImpacted = (canEdit: boolean, saveFn?: any): ColDef => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "totalPeopleImpacted",
      heading: I18n.t("grids.numberImpacted"),
      editable: canEdit,
      clickEditable: true
    }),
    valueSetter: params => {
      if (!params.newValue || !saveFn || !params.node) return false;
      saveFn(ImpactField.numberImpacted, params.node.id, params.newValue);
      return true;
    },
    enableRowGroup: true
  };

  res.type = makeCellDeletable(res.type);

  return res;
};

export const colImpactLevel = (canEdit: boolean, impactLevels?: FP.Generic.IKeyLabel[], saveFn?: any): ColDef => {
  let result: ColDef = {
    ...baseColumn({
      fieldName: "impactLevel",
      heading: I18n.t("grids.impactLevel"),
      width: 150,
      editable: canEdit,
      clickEditable: true
    }),
    cellEditorSelector: () => ({ component: AgGridAutocomplete, popup: true, popupPosition: "over" }),
    cellEditorPopup: true,
    valueSetter: params => {
      if (!params.newValue || !saveFn || !params.node) return false;
      let newVal = Enums.Translator.ImpactLevelShortToLong(+params.newValue.key);
      saveFn(ImpactField.impactLevel, params.node.id, newVal);
      return true;
    },
    cellDataType: "any",
    filter: "agTextColumnFilter",
    filterParams: {
      textMatcher: zeroToTenTextMatcher
    },
    cellEditorParams: {
      field: "impactLevel",
      getValueLabel: (ee: number) => {
        const shortVal = Enums.Translator.ImpactLevelLongToShort(ee);
        return shortVal > 0 ? impactLevels.find(e => e.key === shortVal.toString()).label : "";
      },
      options: impactLevels ?? []
    },
    getQuickFilterText: params => {
      if (params.value === -1) return "";

      return Translator.ImpactLevel(params.value);
    },
    enableRowGroup: true
  };
  result.type = makeCellDeletable(result.type);
  result.cellRenderer = params => {
    const impact: FP.Entities.IImpactSummary = params.data;
    const data = impact?.impactLevel || params.node?.key;

    return data > 0 ? (
      <span>{Enums.Translator.ImpactLevel(data)}</span>
    ) : params.column?.colId?.includes(params.node?.rowGroupColumn?.colId) ? (
      I18n.t("phrases.notAvailable")
    ) : null;
  };

  return result;
};

export const colBusinessAreas = (canEdit: boolean, editFn?): ColDef => {
  let res: ColDef = {
    ...baseDropdownColumn({
      fieldName: "businessAreaNames",
      heading: I18n.t("grids.businessAreas"),
      autoHeight: true,
      width: 500,
      clickEditable: true
    }),
    editable: false,
    filterParams: {
      textMatcher: multiValueTextMatcher
    },
    enableRowGroup: true
  };
  res.cellRenderer = e => {
    const data = e.data?.businessAreaNames || e.getValue();

    if (data !== null && data?.length > 0) {
      return <GridPills text={data} />;
    }
    return (
      <span>{(e.column?.colId?.includes(e.node?.rowGroupColumn?.colId) && I18n.t("phrases.notAvailable")) || ""}</span>
    );
  };
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }
  return res;
};

export const colLocations = (canEdit: boolean, editFn?): ColDef => {
  let res: ColDef = {
    ...baseDropdownColumn({
      fieldName: "locationNames",
      heading: I18n.t("grids.locations"),
      autoHeight: true,
      width: 500,
      clickEditable: true
    }),
    editable: false,
    filterParams: {
      textMatcher: multiValueTextMatcher
    },
    enableRowGroup: true
  };
  res.cellRenderer = e => {
    const data = e.data?.locationNames || e.getValue();

    if (data !== null && data?.length > 0) {
      return <GridPills text={data} />;
    }
    return (
      <span>{(e.column?.colId?.includes(e.node?.rowGroupColumn?.colId) && I18n.t("phrases.notAvailable")) || ""}</span>
    );
  };
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }
  return res;
};

export const colImpactGroups = (canEdit: boolean, editFn?: any): ColDef => {
  let res: ColDef = {
    ...baseDropdownColumn({
      fieldName: "impactGroupNames",
      heading: I18n.t("grids.impactGroups"),
      autoHeight: true,
      width: 500,
      clickEditable: true
    }),
    cellRenderer: e => {
      const data = e.data?.impactGroupNames || e.getValue();

      if (data !== null && data?.length > 0) {
        return <GridPills text={data} />;
      }
      return (
        <span>
          {(e.column?.colId?.includes(e.node?.rowGroupColumn?.colId) && I18n.t("phrases.notAvailable")) || ""}
        </span>
      );
    },
    enableRowGroup: true
  };

  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }

  return res;
};

export const colActions = (canEdit: boolean, editFn?) => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "actionNames",
      heading: I18n.t("grids.actions"),
      editable: false,
      clickEditable: true,
      autoHeight: true,
      width: 500
    }),
    cellRenderer: e => {
      const data = e.data?.actionNames || e.getValue();

      if (data !== null && data?.length > 0) {
        return <GridPills text={data} />;
      }
      return (
        <span>
          {(e.column?.colId?.includes(e.node?.rowGroupColumn?.colId) && I18n.t("phrases.notAvailable")) || ""}
        </span>
      );
    },
    enableRowGroup: true
  };

  if (editFn && canEdit) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }

  return res;
};

export const colProjectStakeholders = (canEdit: boolean, editFn?: any): ColDef => {
  let res: ColDef = {
    ...baseDropdownColumn({
      fieldName: "projectStakeholderNames",
      heading: I18n.t("grids.stakeholders"),
      clickEditable: true
    }),
    autoHeight: true,
    width: 500,
    enableRowGroup: true
  };
  res.cellRenderer = e => {
    const data = e.data?.projectStakeholderNames || e.getValue();

    if (data !== null && data?.length > 0) {
      return <GridPills text={data} />;
    }
    return (
      <span>{(e.column?.colId?.includes(e.node?.rowGroupColumn?.colId) && I18n.t("phrases.notAvailable")) || ""}</span>
    );
  };
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }

  return res;
};

export const colAudiences = (canEdit: boolean, editFn?: any): ColDef => {
  let res: ColDef = {
    ...baseDropdownColumn({
      fieldName: "audienceNames",
      heading: I18n.t("grids.audiences"),
      autoHeight: true,
      width: 500,
      clickEditable: true
    }),
    enableRowGroup: true
  };
  res.cellRenderer = e => {
    const data = e.data?.audienceNames || e.getValue();

    if (data !== null && data?.length > 0) {
      return <GridPills text={data} />;
    }
    return (
      <span>{(e.column?.colId?.includes(e.node?.rowGroupColumn?.colId) && I18n.t("phrases.notAvailable")) || ""}</span>
    );
  };
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }

  return res;
};

export const colMilestones = (canEdit: boolean, editFn?: any): ColDef => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "milestoneNames",
      heading: I18n.t("grids.milestones"),
      autoHeight: true,
      clickEditable: true,
      width: 500
    }),
    enableRowGroup: true
  };
  res.cellRenderer = e => {
    const data = e.data?.milestoneNames || e.getValue();

    if (data !== null && data?.length > 0) {
      return <GridPills text={data} />;
    }
    return (
      <span>{(e.column?.colId?.includes(e.node?.rowGroupColumn?.colId) && I18n.t("phrases.notAvailable")) || ""}</span>
    );
  };
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }
  return res;
};

export const colProcesses = (canEdit: boolean, editFn?: any): ColDef => {
  let res: ColDef = {
    ...baseTextColumn({
      fieldName: "processNames",
      heading: I18n.t("grids.processes"),
      autoHeight: true,
      width: 500,
      clickEditable: true
    }),
    enableRowGroup: true
  };
  res.cellRenderer = e => {
    const data = e.data?.processNames || e.getValue();

    if (data !== null && data?.length > 0) {
      return <GridPills text={data} />;
    }
    return (
      <span>{(e.column?.colId?.includes(e.node?.rowGroupColumn?.colId) && I18n.t("phrases.notAvailable")) || ""}</span>
    );
  };
  const isEditable = canEdit && editFn !== undefined;
  if (isEditable) {
    res.onCellDoubleClicked = e => {
      editFn(e.data);
    };
    res.suppressKeyboardEvent = openModalOnDelete(res, editFn);
  }
  return res;
};
