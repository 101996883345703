import { action, makeObservable, observable } from "mobx";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import TagsApi, { TagsApi as ITagsApi } from "../../../../../../../services/api/v2/tags/Tags.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../services/api/v2/impacts/Impacts.api";

export class ImpactTagsSidebarModel extends BaseModel {
  impactsProvider: IImpactsApi;
  tagsProvider: ITagsApi;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;

  constructor(closeModalFn: () => void, organisationId: number, projectId: number, impactId: number) {
    super();
    makeObservable(this);
    this.impactsProvider = ImpactsApi;
    this.tagsProvider = TagsApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.loadImpact(impactId);
    this.closeModalFn = closeModalFn;
  }

  @action
  setImpact = (impact: FP.Entities.IImpact) => {
    this.impact = impact;
    this.isLoading = false;
  };

  @action
  loadImpact = async (impactId: number) => {
    let res = await this.impactsProvider.getById(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.setImpact(res.payload);
  };

  @action
  removeTag = async (tagId: number) => {
    // let res = await this.tagsProvider.removeImpactTagAssoc(this.organisationId, this.projectId, tagId, this.impact.id);
    // if (!res || res.isError) return;
  };
}
