import { ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import { UiActionRenderers } from "@flightpath/coreui/dist/uiAction/IUiAction";
import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import * as _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import { NavigateFunction } from "react-router-dom";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import { ModalContextModel } from "../../../../../core/modalZ/context/ModalContext_model";
import { IToasterService } from "../../../../../core/toaster/ToasterService";
import { DisposableModel } from "../../../../../core/util/DisposableModel";
import { CommentsApi as ICommentsApi } from "../../../../../services/api/v2/comments/Comments.api";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import { IGridToastService } from "../../../../../services/local/gridToastService/IGridToastService";
import { ActivityProcessGridViewModel } from "./ActivityProcessesGridView/ActivityProcessesGridView_model";
import * as apiFilters from "./ActivityProcessesView_apiFilter";
import {
  SHOW_ACTIVITY_PROCESS_DELETE_CONFIRM_MODAL,
  SHOW_ACTIVITY_PROCESS_IMPACT_GROUP_LINK_MODAL,
  SHOW_ACTIVITY_PROCESS_REVIEW_CONFIRM_MODAL
} from "./ActivityProcessesView_modals";

export class ActivityProcessesViewModel extends DisposableModel {
  commentsProvider: ICommentsApi;
  gridToastService: IGridToastService;
  projectId: number;
  modalService: IModalContextModel;
  projectProcessProvider: IProjectProcessesApi;
  @observable confirmationService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable.ref selectedProcesses: number[] = [];
  toasterService: IToasterService;
  @observable.ref process: FP.Entities.IProject;
  organisationId: number;
  @observable isLoading: boolean = true;
  authUser: FP.Entities.IUser;
  gridApi: GridApi;
  navigate: NavigateFunction;
  filterModel?: any;
  @observable gridModel: ActivityProcessGridViewModel;
  @observable.ref columnDefs: any[];
  @observable searchText: string;
  uiActions = [
    {
      id: "action1",
      label: I18n.t("entities.highLevelImpacts"),
      onAction: ev => {
        this.showLinkActivityProcessesToImpactGroupModal();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: "p-1"
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];

  constructor(projectId: number, organisationId: number, authUser: FP.Entities.IUser, navigate: NavigateFunction) {
    super();
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.projectProcessProvider = ProjectProcessesApi;
    this.modalService = new ModalContextModel();
    this.httpProgress = ProgressIndicatorModel;
  }

  onMount = async () => {
    // let res = await ColumnDefsApi.getActivityProcessColumnDefs(this.organisationId);
    this.setGridModel(new ActivityProcessGridViewModel(this.organisationId, this.projectId, this.authUser));
    await this.gridModel.onMount();

    // this.setColumnDefs(res.payload);
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setColumnDefs = (columnDefs: any[]) => (this.columnDefs = columnDefs);

  @action
  updateSelectedProcesses = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedProcesses = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  @action
  deselectRows = () => {
    if (this.gridApi !== undefined) this.gridApi.deselectAll();
  };

  @action
  setGridModel = (gridModel: any) => {
    this.gridModel = gridModel;
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.filterModel = {
      ...apiFilters.getActivityProcessGridFilters()
    };
    this.gridApi = gridReadyEvent.api;
    this.gridApi.setFilterModel({});

    setTimeout(() => {
      this.gridApi.setFilterModel(this.filterModel);
    });
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };

  reviewActivityProcessRange = async (activityProcessIds: number[], comment: string) => {
    // let res = await this.projectProcessProvider.reviewRange(
    //   this.organisationId,
    //   this.projectId,
    //   activityProcessIds,
    //   comment
    // );
    // if (!res || res.isError) return;
  };

  removeActivityProcessRange = async (activityProcessIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.projectProcessProvider.deleteRange(this.organisationId, this.projectId, activityProcessIds);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;
    return res.payload;
  };

  showActivityProcessConfirmReviewModal = () => {
    return SHOW_ACTIVITY_PROCESS_REVIEW_CONFIRM_MODAL(
      this.modalService,
      this.selectedProcesses,
      this.reviewActivityProcessRange,
      this.toasterService,
      this.deselectRows
    );
  };

  showActivityProcessConfirmDeleteModal = () => {
    return SHOW_ACTIVITY_PROCESS_DELETE_CONFIRM_MODAL(this.selectedProcesses, this.removeActivityProcessRange);
  };

  showLinkActivityProcessesToImpactGroupModal = () => {
    return SHOW_ACTIVITY_PROCESS_IMPACT_GROUP_LINK_MODAL(this.projectId, this.selectedProcesses);
  };
}
