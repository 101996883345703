import { AxiosRequestConfig } from "axios";
import { ActionField, ActivityProcessField, ImpactField, ProjectStakeholderField, StakeholderField } from "../../enums";
import GridToastService from "../../services/local/gridToastService/GridToastService";
import { IFlightPathApiResponse } from "../../services/api/v2/BaseApiModel";
import ProgressIndicatorModel from "../../components/widgets/ProgressIndicator/ProgressIndicator_model";

export interface IGridFns {
  updateTextField: (field: ImpactField, entityId: number, text: string) => void;
  updateIdField: (field: ImpactField, entityId: number, id: number) => void;
  updateDateField: (field: ImpactField, entityId: number, date: Date) => void;
  updateNumericField: (field: ImpactField, entityId: number, numeric: number) => void;
}

interface ProviderParam {
  updateField: (
    organisationId: number,
    projectId: number,
    entityId: number,
    textField: string,
    idField: number,
    dateField: Date,
    numericField: number,
    field: ImpactField | ActionField | ProjectStakeholderField | StakeholderField | ActivityProcessField,
    config?: AxiosRequestConfig
  ) => Promise<IFlightPathApiResponse<any>>;
}

export const GetGridFns = (provider: ProviderParam, organisationId: number, projectId: number): IGridFns => {
  const gridToastService = GridToastService;
  const httpProgress = ProgressIndicatorModel;

  const updateTextField = async (
    field: ImpactField | ActionField | ProjectStakeholderField,
    entityId: number,
    text: string
  ): Promise<boolean> => {
    httpProgress.showTopProgressBarVisible();
    let res = await provider.updateField(organisationId, projectId, entityId, text, 0, null, 0, field);
    httpProgress.hideTopProgressBarVisible();
    if (res) {
      gridToastService.showToast(res.code, res.message);
      return true;
    }

    return false;
  };
  const updateIdField = async (
    field: ImpactField | ActionField | ProjectStakeholderField,
    entityId: number,
    id: number
  ) => {
    httpProgress.showTopProgressBarVisible();
    let res = await provider.updateField(organisationId, projectId, entityId, "", id, null, 0, field);
    httpProgress.hideTopProgressBarVisible();
    if (res) {
      gridToastService.showToast(res.code, res.message);
    }
  };

  const updateDateField = async (
    field: ImpactField | ActionField | ProjectStakeholderField,
    entityId: number,
    date: Date
  ) => {
    httpProgress.showTopProgressBarVisible();
    let res = await provider.updateField(organisationId, projectId, entityId, "", 0, date, 0, field);
    httpProgress.hideTopProgressBarVisible();
    if (res) {
      gridToastService.showToast(res.code, res.message);
    }
  };

  const updateNumericField = async (
    field: ImpactField | ActionField | ProjectStakeholderField,
    entityId: number,
    numeric: number
  ) => {
    httpProgress.showTopProgressBarVisible();
    let res = await provider.updateField(organisationId, projectId, entityId, "", 0, null, numeric, field);
    httpProgress.hideTopProgressBarVisible();
    if (res) {
      gridToastService.showToast(res.code, res.message);
    }
  };

  return {
    updateTextField,
    updateDateField,
    updateIdField,
    updateNumericField
  };
};
