import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { SingleForm } from "../../../../../../../change/forms/singleFormModel/SingleForm_view";
import { ActivityProcessImpactGroupsSidebarModel } from "./ActivityProcessImpactGroupsSidebar_model";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { useParams } from "react-router-dom";

interface ActivityProcessImpactGroupsSidebarProps {
  projectProcessId: number;
}

export const ActivityProcessImpactGroupsSidebar: React.FC<ActivityProcessImpactGroupsSidebarProps> = observer(
  ({ projectProcessId }) => {
    const { organisationId, projectId } = useParams();
    const [model] = useState(
      () => new ActivityProcessImpactGroupsSidebarModel(+organisationId, +projectId, projectProcessId)
    );

    useEffect(() => {
      model.onMount();
      return model.onUnmount;
    }, [model]);

    if (model.isLoading) return <PositionedSpinner />;

    return (
      <div className="container-fluid">
        <SingleForm model={model.formModel} />
      </div>
    );
  }
);
