import React, { useEffect, useState } from "react";
import { IF } from "../../../../../../../components/hoc/If";
import { useCurrentOrganisationId } from "../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { ImpactGroupGridModel } from "./ImpactGroupGrid_model";
import { GridView } from "../../../../../../../core/grids/GridView_view";
import { ImpactGroupGridViewModel } from "./ImpactGroupGridView_model";
import { Button, ButtonTypes } from "../../../../../../../components/ui/Button";
import { Enums, UiSizes } from "../../../../../../../enums";
import { observer } from "mobx-react";
import { Input } from "../../../../../../../components/ui/_forms/Input";
import { IconSymbols } from "../../../../../../../components/ui/Icon";
import { useFlightPathUser } from "../../../../../../../core/auth_insight";
import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { ButtonIcon, ButtonIconShapes } from "@flightpath/coreui/dist/ui/Button";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";

export const ImpactGroupGrid: React.FC<{
  projectId: number;
  onAssignSuccess: () => void;
  activityProcessIds: number[];
}> = observer(({ projectId, onAssignSuccess, activityProcessIds }) => {
  const organisationId = useCurrentOrganisationId();
  const authUser = useFlightPathUser();
  const [model] = useState(() => new ImpactGroupGridModel(projectId, organisationId, authUser, activityProcessIds));
  const [gridModel] = useState(() => new ImpactGroupGridViewModel(organisationId, projectId, authUser));

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) return <PositionedSpinner />;

  return (
    <div className="impact-grid">
      <div className="container-fluid py-3">
        <div className="row">
          <div className="col-10">
            <Button
              type={ButtonTypes.OUTLINE_PRIMARY}
              size={UiSizes.SM}
              isDisabled={model.selectedImpactGroups.length === 0}
              className="mt-2 mr-2 align-self-start"
              onClick={async () => {
                let res = await model.assignImpactGroups();
                if (res) {
                  onAssignSuccess();
                }
              }}
            >
              Assign
            </Button>
            <Input
              autoFocus={true}
              className="mt-2"
              size={UiSizes.XS}
              onChange={model.setSearchText}
              placeholder="Search"
              icon={IconSymbols.Search}
              iconSize={Enums.UiSizes.SM}
              iconClass="mt-1"
              style={{ height: 32 }}
            />
          </div>
          <div className="col-2 d-flex align-items-end px-0 justify-content-end">
            <ButtonIcon
              size={UiSizes.SM}
              onClick={ModalContext.hide}
              type={ButtonTypes.OUTLINE_PRIMARY}
              shape={ButtonIconShapes.RECTANGLE}
              symbol={IconSymbols.Close}
            />
          </div>
        </div>
      </div>
      <IF condition={!model.isLoading}>
        <GridView
          context={{}}
          data={model.gridImpactGroups}
          users={[]}
          onCellClicked={() => {}}
          onCellEditModeChange={() => {}}
          onSelectionChanged={model.updateSelectedImpactGroups}
          model={gridModel}
          isLoading={gridModel.isLoading}
          onGridReady={model.onGridReady}
          // ref={filterRef}
          // filterHasChangedFn={setHasFilters}
          // isExternalFilterPresent={model.isExternalFilterPresent}
          // doesExternalFilterPass={model.doesExternalFilterPass}
          overlayNoRowsTemplate={null}
        />
      </IF>
    </div>
  );
});
