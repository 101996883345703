import { makeObservable, observable } from "mobx";
import { IGridModel } from "../../../../../../core/grids/IGridModel";
import { BaseModel } from "../../../../../../core/util/BaseModel";
import { EntityTypes } from "../../../../../../enums";
import ProjectProcessesApi, {
  ProjectProcessesApi as IProjectProcessesApi
} from "../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import { GetActivityProcessGridViewColumns } from "./ActivityProcessesGridView_columns";
import { getProcessGridModals } from "./modals/ActivityProcessesGridView_modals";

export class ActivityProcessGridViewModel extends BaseModel implements IGridModel {
  projectProcessProvider: IProjectProcessesApi;
  // processHub: IProcessesHub;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  type: EntityTypes = EntityTypes.ACTIVITY_PROCESSES;
  organisationId: number;
  projectId: number;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    super();
    makeObservable(this);
    this.projectProcessProvider = ProjectProcessesApi;
    this.projectTeamUserPermissionsProvider = ProjectTeamUserPermissionsApi;
    this.authUser = authUser;
    this.organisationId = organisationId;
    this.projectId = projectId;
  }

  onMount = async () => {};

  onUnmount = async () => {};

  getColumnConfig = () => {
    const s = GetActivityProcessGridViewColumns({
      canEdit: true,
      organisationId: this.organisationId,
      projectId: this.projectId,
      gridFns: null,
      modals: getProcessGridModals(this.organisationId, this.projectId),
      pinned: "left"
    });
    return s;
  };
}
