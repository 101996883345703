import { Panel } from "@flightpath/coreui";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IF } from "../../../../../components/hoc/If";
import { ButtonIcon, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { TimelineView } from "../../../../../components/widgets/TimelineWidget/Timeline_view";
import { CanEdit } from "../../../../../contexts/permissions/PermissionHelpers";
import { useCanViewField } from "../../../../../contexts/permissions/PermissionHooks";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";
import { useUserIdentity } from "../../../../../core/auth_insight";
import I18n from "../../../../../core/localization/I18n";
import { useModalContext } from "../../../../../core/modalZ/context/ModalContext";
import Pages from "../../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { TimelineMilestoneLegend } from "../../../organisations/organisationDashboard/dashboardContentComponent/DashboardTimeline/components/TimelineMilestoneLegend";
import { INFO_MODAL_CONFIG } from "../ProjectView_modals";
import { CountDisplay } from "../components/CountDisplay";
import { ReportingPie } from "../components/ReportingPie";
import { Animations } from "../../../../../core/util/Animations";

export const ProjectMainPanel: React.FC<any> = observer(({ parentModel }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string; organisationId: string }>();
  const { given_name, family_name } = useUserIdentity();
  const navigate = useNavigate();
  const {
    project,
    impactData,
    impactCompletionData,
    impactPercentComplete,
    ragData,
    actionsCompletionData,
    actionPercentComplete,
    stakeholderData,
    audienceData
  } = parentModel;

  const impactLevelColoursArray = ["#093D4C", "#095971", "#308096", "#BEBEBE"];
  const impactAndActionCompletionDataColoursArray = [
    "#D3D3D3",
    "#FFD700",
    "#1AC541",
    "#FFA07A",
    "#B0E0E6",
    "#C0C0C0",
    "#ADD8E6"
  ];
  const ragColourArray = ["#F91E1E", "#F9AB1E", "#1AC541", "#58C8D1", "#BEBEBE"];
  const stakeholderColourArray = ["#3d00c1", "#2AE1EE", "#f7e6c3", "#ed9aed", "#BEBEBE"];
  const audienceColourArray = ["#3d00c1", "#2AE1EE", "#f7e6c3", "#ed9aed", "#BEBEBE"];
  // const baseUrl = `/organisations/${organisationId}/projects/${projectId}`;
  const canViewImpacts = useCanViewField(PermissionFields.IMPACTS, organisationId, +projectId);
  const canViewStakeholders = useCanViewField(PermissionFields.STAKEHOLDERS, organisationId, +projectId);
  const canViewActions = useCanViewField(PermissionFields.ACTIONS, organisationId, +projectId);
  const modal = useModalContext();

  const impactTypeOnClickFunction = (dataObject: any) => {
    let level = dataObject.id.charAt(0).toUpperCase() + dataObject.id.slice(1);
    let url = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}/?level=${level}`;
    navigate(url);
  };
  const impactProgressOnClickFunction = (dataObject: any) => {
    let url = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}/?progressStatus=${
      dataObject.label
    }`;
    navigate(url);
  };
  const actionRagOnClickFunction = (dataObject: any) => {
    let url = "";
    if (dataObject.label === "Completed") {
      url = `${Pages.projects.actions.listView.generateLink(organisationId, projectId)}?progressStatus=${
        dataObject.label
      }`;
    } else {
      url = `${Pages.projects.actions.listView.generateLink(organisationId, projectId)}?ragStatus=${dataObject.label}`;
    }
    navigate(url);
  };
  const actionProgressOnClickFunction = (dataObject: any) => {
    let url = `${Pages.projects.actions.listView.generateLink(organisationId, projectId)}?progressStatus=${
      dataObject.label
    }`;
    navigate(url);
  };

  const stakeholderMapUrl = (tabId?: number, mapId?: number) => {
    return Pages.projects.stakeholders.visualisations.parameters.generateLink(
      organisationId,
      projectId,
      tabId || 1,
      mapId || 0
    );
  };

  const stakeholdersMapOnClickFunction = (tabId?: number, mapId?: number) => {
    navigate(stakeholderMapUrl(tabId, mapId));
  };

  return (
    <div className={`${Animations.FP_ZOOM_IN} project-main-panel container-fluid py-5`}>
      <div className="row mb-4">
        <div className="col">
          <div className="d-flex align-items-center">
            <h1 className="mb-0">
              {I18n.t("phrases.project")} | {project?.name}
            </h1>
            <CanEdit field={PermissionFields.PROJECTS} projectId={+projectId}>
              <LinkButton
                id="manageProjectButton"
                className="ml-auto mr-2"
                type={ButtonTypes.PRIMARY}
                href={`${Pages.projects.settings.index.generateLink(organisationId, projectId)}`}
              >
                {I18n.t("phrases.manageProject")}
              </LinkButton>
            </CanEdit>
            <ButtonIcon
              symbol={IconSymbols.MenuArrowRight}
              onClick={() => modal.show(INFO_MODAL_CONFIG(parentModel))}
              type={ButtonTypes.LINK}
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12">
          {project && (
            <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className="pb-4">
              <TimelineView
                model={parentModel.timelineModel}
                hideControls={true}
                timelineProps={{ lineHeight: 40 } as any}
                className={`project-overview__timeline`}
                labels={{
                  noItemsText: I18n.t("phrases.noProjectsOrMilestones"),
                  today: I18n.t("phrases.today"),
                  scrollLeft: I18n.t("phrases.scrollLeft"),
                  scrollRight: I18n.t("phrases.scrollRight"),
                  zoomOut: I18n.t("phrases.zoomOut"),
                  zoomIn: I18n.t("phrases.zoomIn"),
                  resetZoom: I18n.t("phrases.resetZoom")
                }}
              />
            </Panel.Panel>
          )}
        </div>
        {project?.milestones && (
          <div className="col-12 mb-2">
            <TimelineMilestoneLegend milestoneTypes={project.milestones.map(e => e.milestoneType)} />
          </div>
        )}
      </div>

      <div className="row mb-5">
        <div className="col-12 col-md-6 mb-3 mb-xl-0">
          <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className="p-3">
            <div className="row">
              <div className="col-5 p-0">
                <div className="col p-0">
                  <CountDisplay
                    count={parentModel.impacts?.total}
                    canView={canViewImpacts}
                    url={`${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}`}
                    label={I18n.t("phrases.totalImpacts")}
                    permissionLabel={I18n.t("warnings.noImpactAccessPanel")}
                  />
                </div>
              </div>
              <IF condition={!isNaN(impactPercentComplete)}>
                <div className="col-7 p-0">
                  <ReportingPie
                    cls={"p-0"}
                    data={impactData}
                    colourArray={impactLevelColoursArray}
                    onClick={impactTypeOnClickFunction}
                  />
                </div>
              </IF>
            </div>
          </Panel.Panel>
        </div>
        <div className="col-12 col-md-6 mb-3 mb-xl-0">
          <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className="p-3">
            <div className="row">
              <IF condition={!isNaN(impactPercentComplete)}>
                <div className="col-5 p-0">
                  <div className="col p-0">
                    <CountDisplay
                      count={`${impactPercentComplete}%`}
                      canView={canViewImpacts}
                      url={`${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}`}
                      label={I18n.t("phrases.impactsProgress")}
                      permissionLabel={I18n.t("warnings.noImpactAccessPanel")}
                      subtitle={I18n.t("phrases.impactsComplete")}
                    />
                  </div>
                </div>
              </IF>
              <IF condition={!isNaN(impactPercentComplete)}>
                <div className="col-7 p-0">
                  <ReportingPie
                    cls={"p-0"}
                    data={impactCompletionData}
                    colourArray={impactAndActionCompletionDataColoursArray}
                    onClick={impactProgressOnClickFunction}
                  />
                </div>
              </IF>
            </div>
          </Panel.Panel>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 col-md-6 mb-3 mb-xl-0">
          <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className=" p-3">
            <div className="row">
              <div className="col-5 p-0">
                <div className="col p-0">
                  <CountDisplay
                    canView={canViewActions}
                    count={parentModel.actions?.total}
                    url={`${Pages.projects.actions.listView.generateLink(organisationId, projectId)}/actions`}
                    label={I18n.t("phrases.totalActions")}
                    permissionLabel={I18n.t("warnings.noActionAccessPanel")}
                  />
                </div>
              </div>
              <IF condition={!isNaN(actionPercentComplete)}>
                <div className="col-7 p-0">
                  <ReportingPie
                    cls={"p-0"}
                    data={ragData}
                    colourArray={ragColourArray}
                    onClick={actionRagOnClickFunction}
                  />
                </div>
              </IF>
            </div>
          </Panel.Panel>
        </div>
        <div className="col-12 col-md-6 mb-3 mb-xl-0">
          <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className=" p-3">
            <div className="row">
              <IF condition={!isNaN(actionPercentComplete)}>
                <div className="col-5 p-0">
                  <div className="col p-0">
                    <CountDisplay
                      canView={canViewActions}
                      count={`${actionPercentComplete}%`}
                      url={`${Pages.projects.actions.listView.generateLink(organisationId, projectId)}/actions`}
                      label={I18n.t("phrases.actionsProgress")}
                      permissionLabel={I18n.t("warnings.noActionAccessPanel")}
                      subtitle={I18n.t("phrases.actionsComplete")}
                    />
                  </div>
                </div>
              </IF>
              <IF condition={!isNaN(impactPercentComplete)}>
                <div className="col-7 p-0">
                  <ReportingPie
                    cls={"p-0"}
                    data={actionsCompletionData}
                    colourArray={impactAndActionCompletionDataColoursArray}
                    onClick={actionProgressOnClickFunction}
                  />
                </div>
              </IF>
            </div>
          </Panel.Panel>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 col-md-6 mb-3 mb-xl-0">
          <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className=" p-3">
            <div className="row">
              <div className="col-5 p-0">
                <div className="col p-0">
                  <CountDisplay
                    count={parentModel.stakeholders?.total}
                    url={`${Pages.projects.stakeholders.listView.generateLink(organisationId, projectId)}`}
                    label={I18n.t("phrases.totalStakeholders")}
                    canView={canViewStakeholders}
                    permissionLabel={I18n.t("warnings.noStaholderAccessPanel")}
                  />
                </div>
              </div>
              <IF condition={!!parentModel.stakeholders?.total}>
                <div className="col-7 p-0">
                  <ReportingPie
                    cls={"p-0"}
                    data={stakeholderData}
                    colourArray={stakeholderColourArray}
                    onClick={() => stakeholdersMapOnClickFunction()}
                  />
                </div>
              </IF>
            </div>
          </Panel.Panel>
        </div>
        <div className="col-12 col-md-6 mb-3 mb-xl-0">
          <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className=" p-3">
            <div className="row">
              <div className="col-5 p-0">
                <div className="col p-0">
                  <CountDisplay
                    count={parentModel.audiences?.total}
                    url={`${Pages.projects.audiences.listView.generateLink(organisationId, projectId)}`}
                    label={I18n.t("phrases.totalAudiences")}
                    canView={canViewStakeholders}
                    permissionLabel={I18n.t("warnings.noStaholderAccessPanel")}
                  />
                </div>
              </div>
              <IF condition={!!parentModel.audiences?.total}>
                <div className="col-7 p-0">
                  <ReportingPie
                    cls={"p-0"}
                    data={audienceData}
                    colourArray={audienceColourArray}
                    onClick={() => stakeholdersMapOnClickFunction(2)}
                  />
                </div>
              </IF>
            </div>
          </Panel.Panel>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12 mb-3 mb-xl-0">
          <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className="p-3">
            <>
              <h3>Quick Links</h3>
              {isNaN(impactPercentComplete) &&
              isNaN(actionPercentComplete) &&
              !parentModel.stakeholders?.total &&
              !parentModel.audiences?.total ? (
                <small>No Quick links, start populating the project to get the links</small>
              ) : null}
              {!isNaN(impactPercentComplete) ? (
                <LinkButton
                  href={`${Pages.projects.impacts.listView.generateLink(
                    organisationId,
                    projectId
                  )}?owner=${given_name}%20${family_name}`}
                  target="_blank"
                  size={UiSizes.MD}
                  type={ButtonTypes.OUTLINE_PRIMARY}
                  className="mb-2 mr-2"
                >
                  <small>{I18n.get("phrases.myImpacts")}</small>
                </LinkButton>
              ) : null}

              {!isNaN(actionPercentComplete) ? (
                <>
                  <LinkButton
                    href={`${Pages.projects.actions.listView.generateLink(
                      organisationId,
                      projectId
                    )}?owner=${given_name}%20${family_name}`}
                    target="_blank"
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    size={UiSizes.MD}
                    className="mr-2 mb-2"
                  >
                    <small>My Actions</small>
                  </LinkButton>
                  <LinkButton
                    href={`${Pages.projects.actions.listView.generateLink(
                      organisationId,
                      projectId
                    )}?assignedToName=${given_name}%20${family_name}`}
                    target="_blank"
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    size={UiSizes.MD}
                    className="mr-2 mb-2"
                  >
                    <small>Assigned Actions</small>
                  </LinkButton>
                </>
              ) : null}
              {!!parentModel.stakeholders?.total ? (
                <>
                  <LinkButton
                    href={`${Pages.projects.stakeholders.listView.generateLink(
                      organisationId,
                      projectId
                    )}/?owner=${given_name}%20${family_name}`}
                    target="_blank"
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    size={UiSizes.MD}
                    className="mr-2 mb-2"
                  >
                    <small>My stakeholders</small>
                  </LinkButton>

                  <LinkButton
                    href={stakeholderMapUrl(1, 3)}
                    target="_blank"
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    size={UiSizes.MD}
                    className="mr-2 mb-2"
                  >
                    <small>Commitment stakeholder map</small>
                  </LinkButton>
                  <LinkButton
                    href={stakeholderMapUrl(1, 1)}
                    target="_blank"
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    size={UiSizes.MD}
                    className="mr-2 mb-2"
                  >
                    <small>Sentiment stakeholder map</small>
                  </LinkButton>
                  <LinkButton
                    href={stakeholderMapUrl(1, 2)}
                    target="_blank"
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    size={UiSizes.MD}
                    className="mr-2 mb-2"
                  >
                    <small>Receptiveness stakeholder map</small>
                  </LinkButton>
                </>
              ) : null}
              {!!parentModel.audiences?.total ? (
                <>
                  <LinkButton
                    href={`${Pages.projects.audiences.listView.generateLink(
                      organisationId,
                      projectId
                    )}/?owner=${given_name}%20${family_name}`}
                    target="_blank"
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    size={UiSizes.MD}
                    className="mr-2 mb-2"
                  >
                    <small>My audiences</small>
                  </LinkButton>

                  <LinkButton
                    href={stakeholderMapUrl(2, 3)}
                    target="_blank"
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    size={UiSizes.MD}
                    className="mr-2 mb-2"
                  >
                    <small>Commitment audience map</small>
                  </LinkButton>
                  <LinkButton
                    href={stakeholderMapUrl(2, 1)}
                    target="_blank"
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    size={UiSizes.MD}
                    className="mr-2 mb-2"
                  >
                    <small>Sentiment audience map</small>
                  </LinkButton>
                  <LinkButton
                    href={stakeholderMapUrl(2, 2)}
                    target="_blank"
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    size={UiSizes.MD}
                    className="mr-2 mb-2"
                  >
                    <small>Receptiveness audience map</small>
                  </LinkButton>
                </>
              ) : null}
            </>
          </Panel.Panel>
        </div>
      </div>
    </div>
  );
});
