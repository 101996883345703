import { action, makeObservable, observable } from "mobx";
import PermissionsContext from "../../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../../contexts/permissions/PermissionsTypes";
import { ITreeDataItem } from "../../../../../../../core/forms/controls/searchTreePicker/ISearchTreePickerModel";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import ImpactTypesApi, {
  ImpactTypesApi as IImpactTypesApi
} from "../../../../../../../services/api/v2/impactTypes/ImpactTypes.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../../services/api/v2/impacts/Impacts.api";
import { SingleFormModel } from "../../../../../../change/forms/singleFormModel/SingleForm_model";
import {
  getImpactProcessesSidebarFormActions,
  getImpactProcessesSidebarFormFields
} from "./ImpactProcessesSidebar_fields";

export class ImpactProcessesSidebarModel extends BaseModel {
  impactTypesProvider: IImpactTypesApi;
  impactsProvider: IImpactsApi;
  @observable isLoading: boolean = true;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  closeModalFn: () => void;
  processes: ITreeDataItem[];
  formModel: SingleFormModel;
  canEdit: boolean;
  @observable impactId: number;

  constructor(closeModalFn: () => void, organisationId: number, projectId: number, impactId: number) {
    super();
    makeObservable(this);
    this.impactTypesProvider = ImpactTypesApi;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactsProvider = ImpactsApi;
    this.loadSidebar(impactId);
    this.closeModalFn = closeModalFn;
    this.canEdit = PermissionsContext.canEditField(PermissionFields.IMPACTS, this.organisationId, this.projectId);
  }

  @action
  getProcesses = async (impactId: number) => {
    let res = await this.impactsProvider.getProcessSelection(this.organisationId, this.projectId, impactId);
    if (!res || res.isError) return;
    this.processes = res.payload;
  };

  @action
  loadSidebar = async (impactId: number) => {
    this.isLoading = true;
    this.impactId = impactId;
    await this.getProcesses(this.impactId);
    this.loadForm();
    this.isLoading = false;
  };

  loadForm = () => {
    this.formModel = new SingleFormModel();
    this.formModel.formFields = getImpactProcessesSidebarFormFields(this.processes);
    this.formModel.actions = getImpactProcessesSidebarFormActions(this.updateImpact, this.canEdit, this.closeModalFn);
  };

  updateImpact = async () => {
    if (!this.canEdit) return;
    this.formModel.isSaving = true;
    let res = await this.formModel.submit();
    if (!res) return;

    await this.impactsProvider.updateProcesses(
      this.organisationId,
      this.projectId,
      this.impactId,
      res.businessProcesses
    );
  };
}
