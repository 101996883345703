import { GridApi, GridReadyEvent, SelectionChangedEvent } from "ag-grid-community";
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { ButtonTypes } from "../../../../components/ui/Button";
import { getGapAnalysisParameter, getValueParameter } from "../../../../core/grids/GridQueryStringHelpers";

import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import I18n from "../../../../core/localization/I18n";
import { IModalContextModel } from "../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../core/modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import { Enums, ImpactField } from "../../../../enums";
import Pages from "../../../../routes/InsightRoutes";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../services/api/v2/actions/Actions.api";
import CommentsApi, { CommentsApi as ICommentsApi } from "../../../../services/api/v2/comments/Comments.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../services/api/v2/impacts/Impacts.api";
import ProjectsApi, { ProjectsApi as IProjectsApi } from "../../../../services/api/v2/projects/Projects.api";
import GridToastService from "../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../services/local/gridToastService/IGridToastService";
import { getEntityNameMicroFormFields } from "../../../change/forms/microForm/getEntityNameMicroFormFields";
import { SingleFormModel } from "../../../change/forms/singleFormModel/SingleForm_model";
import { SHOW_CONFIRM_CREATION_MODAL } from "../Actions/ActionListView/ActionListView_modals";
import * as apiFilters from "./ImpactsView_apiFilter";
import {
  SHOW_IMPACT_BULK_EDIT_MODAL,
  SHOW_IMPACT_DELETE_CONFIRM_MODAL,
  SHOW_IMPACT_REVIEW_CONFIRM_MODAL,
  SHOW_LINK_TO_BUSINESS_AREAS,
  SHOW_LINK_TO_IMPACT_GROUPS,
  SHOW_IMPACT_UPLOADER_MODAL,
  SHOW_LINK_TO_LOCATIONS,
  SHOW_STAKEHOLDER_ACTION_LINK_MODAL,
  SHOW_STAKEHOLDER_IMPACT_LINK_MODAL,
  SHOW_LABELS_LINK_MODAL
} from "./ImpactsView_modals";
import { ImpactGridViewModel } from "./ImpactGridView/ImpactGridView_model";
import ColumnDefsApi from "../../../../services/api/v2/columnDefs/ColumnDefs.api";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";

export class ImpactsViewModel extends DisposableModel {
  projectProvider: IProjectsApi;
  impactProvider: IImpactsApi;
  commentsProvider: ICommentsApi;
  actionProvider: IActionsApi;
  gridToastService: IGridToastService;
  projectId: number;
  modalService: IModalContextModel;
  @observable confirmationService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable.ref selectedImpacts: number[] = [];
  toasterService: IToasterService;
  @observable.ref project: FP.Entities.IProject;
  @observable isDuplicatingImpacts: boolean = false;
  organisationId: number;
  @observable impactGroupCount: number;
  @observable isLoading: boolean = true;
  authUser: FP.Entities.IUser;
  microImpactForm: SingleFormModel;
  gridApi: GridApi;
  navigate: NavigateFunction;
  filterModel?: any;
  @observable gridModel: ImpactGridViewModel;
  @observable.ref columnDefs: any[];
  @observable gapAnalysisFilter: string;
  @observable gapAnalysisCompleteData: boolean;
  @observable searchText: string;
  actions = [
    {
      id: "action1",
      label: I18n.t("entities.stakeholders"),
      onAction: ev => {
        this.showLinkToStakeholders();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action2",
      label: I18n.t("entities.actions"),
      onAction: async ev => {
        this.showLinkToActions();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action3",
      label: I18n.t("entities.locations"),
      onAction: async ev => {
        this.showLinkToLocations();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action4",
      label: I18n.t("entities.businessAreas"),
      onAction: async ev => {
        this.showLinkToBusinessAreas();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action5",
      label: I18n.t("entities.impactGroups"),
      onAction: async ev => {
        this.showLinkToImpactGroups();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action6",
      label: I18n.t("entities.tags"),
      onAction: async ev => {
        this.showLinkToTags();
      },
      componentProps: {
        type: ButtonTypes.LINK,
        className: ""
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];
  projectStakeholderProvider: IProjectStakeholdersApi;

  constructor(projectId: number, organisationId: number, authUser: FP.Entities.IUser, navigate: NavigateFunction) {
    super();
    makeObservable(this);
    this.impactProvider = ImpactsApi;
    this.actionProvider = ActionsApi;
    this.commentsProvider = CommentsApi;
    this.projectProvider = ProjectsApi;
    this.projectStakeholderProvider = ProjectStakeholdersApi;
    this.toasterService = ToasterService;
    this.modalService = ModalContext;
    this.confirmationService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.projectId = projectId;
    this.gridToastService = GridToastService;
    this.organisationId = organisationId;
    this.authUser = authUser;
    this.setCountsForImpactAndImpactGroup();
    this.navigate = navigate;
    this.setMicroAddForm();
  }

  @action
  setImpactGroupCount = count => {
    this.impactGroupCount = count;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setCountsForImpactAndImpactGroup = async () => {
    let res = await this.projectProvider.getImpactsAndImpactGroupsCount(this.organisationId, this.projectId);
    this.setImpactGroupCount(res.payload.impactGroupCount);
  };

  @action
  updateSelectedImpacts = (event: SelectionChangedEvent) => {
    this.gridApi = event.api;
    this.selectedImpacts = _.map(event.api.getSelectedNodes(), e => {
      return e.data.id;
    });
  };

  @action
  deselectRows = () => {
    if (this.gridApi !== undefined) this.gridApi.deselectAll();
  };

  @action
  onGridReady = (gridReadyEvent: GridReadyEvent): void => {
    this.filterModel = {
      ...apiFilters.getImpactGridFilters()
    };
    this.gridApi = gridReadyEvent.api;
    this.gridApi.setFilterModel({});

    setTimeout(() => {
      this.gridApi.setFilterModel(this.filterModel);
    });
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-impact-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedImpacts && this.selectedImpacts.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  @action
  setGridModel = (gridModel: any) => {
    this.gridModel = gridModel;
  };

  onMount = async () => {
    let res = await ColumnDefsApi.getImpactColumnDefs(this.organisationId);
    this.setGridModel(new ImpactGridViewModel(this.organisationId, this.projectId, this.authUser, res.payload));
    await this.gridModel.onMount();

    this.setColumnDefs(res.payload);
    this.setIsLoading(false);
  };

  @action
  setColumnDefs = (columnDefs: any[]) => (this.columnDefs = columnDefs);

  onUnmount = () => {};

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    return res.payload;
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.navigate(
        Pages.projects.impacts.visualisations.impactAssesment.generateLink(this.organisationId, this.projectId)
      );
      return;
    }

    if (newTabIndex === 0) {
      this.navigate(Pages.projects.impactGroups.listView.generateLink(this.organisationId, this.projectId));
      return;
    }
    this.navigate(Pages.projects.impacts.listView.generateLink(this.organisationId, this.projectId));
  };

  @action
  deleteFieldData = async (impactId: number, impactField: ImpactField) => {
    const res = await this.impactProvider.deleteGridField(this.organisationId, this.projectId, impactId, impactField);
    if (res.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  @action
  setMicroAddForm = () => {
    this.microImpactForm = new SingleFormModel();
    this.microImpactForm.formFields = getEntityNameMicroFormFields(
      this.createMicroImpact,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.impact") })
    );
  };

  showImpactConfirmDeleteModal = () => {
    return SHOW_IMPACT_DELETE_CONFIRM_MODAL(this.modalService, this.selectedImpacts, this.removeImpacts);
  };

  showLinkToStakeholders = () => {
    return SHOW_STAKEHOLDER_IMPACT_LINK_MODAL(this.modalService, this.projectId, this.assignStakeholderToImpacts);
  };

  showLinkToActions = () => {
    return SHOW_STAKEHOLDER_ACTION_LINK_MODAL(this.modalService, this.projectId, this.assignActionsToImpacts);
  };

  showBulkEditModal = () => {
    return SHOW_IMPACT_BULK_EDIT_MODAL(this.modalService, this.projectId, this.organisationId, this.selectedImpacts);
  };

  showLinkToLocations = () => {
    return SHOW_LINK_TO_LOCATIONS(this.modalService, this.assignLocationsToImpacts);
  };

  showLinkToBusinessAreas = () => {
    return SHOW_LINK_TO_BUSINESS_AREAS(this.modalService, this.assignBusinessAreasToImpacts);
  };

  showLinkToImpactGroups = () => {
    return SHOW_LINK_TO_IMPACT_GROUPS(this.modalService, this.projectId, this.assignImpactGroupsToImpacts);
  };

  showLinkToTags = () => {
    return SHOW_LABELS_LINK_MODAL(this.modalService, this.projectId, this.assignLabelsToImpacts);
  };

  duplicateImpacts = async () => {
    this.httpProgress.showTopProgressBarVisible();
    this.isDuplicatingImpacts = true;
    await this.impactProvider.duplicateImpacts(this.organisationId, this.projectId, this.selectedImpacts);
    this.httpProgress.hideTopProgressBarVisible();
    this.isDuplicatingImpacts = false;
  };

  showUploaderModel = () => {
    SHOW_IMPACT_UPLOADER_MODAL(this.modalService);
  };

  showImpactConfirmReviewModal = () => {
    return SHOW_IMPACT_REVIEW_CONFIRM_MODAL(
      this.modalService,
      this.selectedImpacts,
      this.reviewImpacts,
      this.toasterService,
      this.deselectRows
    );
  };

  reviewImpacts = async (impactIds: number[], comment: string) => {
    let res = await this.impactProvider.reviewRange(this.organisationId, this.projectId, impactIds, comment);

    if (!res || res.isError) return;
  };

  removeImpacts = async (impactIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.impactProvider.deleteRange(this.organisationId, this.projectId, impactIds);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  @action
  createMicroImpact = async () => {
    let impactFormRes = await this.microImpactForm.submit();
    this.microImpactForm.isSaving = true;
    if (!impactFormRes) return;

    impactFormRes = { ...impactFormRes, projectId: this.projectId, tags: [], impactTypes: [] };

    this.httpProgress.showOverlay();
    let impactExists = await this.impactProvider.getFiltered(this.organisationId, this.projectId, {
      filters: `name==${impactFormRes.name},lifecycleStatus==${Enums.LifecycleStatus.Active},projectId==${this.projectId}`
    });
    if (impactExists && !impactExists.isError && impactExists.payload.length) {
      this.httpProgress.hideOverlay();
      let confirmCreateImpact = await this.confirmCreateImpact(impactFormRes.name);
      if (!confirmCreateImpact) return;
      this.httpProgress.showOverlay();
    }

    const res = {
      ...impactFormRes
    };

    this.httpProgress.showOverlay();
    const result = await this.impactProvider.create(this.organisationId, this.projectId, res as FP.Entities.IImpact);
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    const impact = result.payload;
    this.microImpactForm.resetFields();
    this.toasterService
      .showSuccessToast()
      .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.impact") })}</span>)
      .startTimer(TOASTER_TOAST_TIME.NORMAL);

    return impact;
  };

  confirmCreateImpact = async (name: string): Promise<boolean> => {
    return SHOW_CONFIRM_CREATION_MODAL(this.modalService, name);
  };

  isExternalFilterPresent = (): boolean => {
    let gapAnalysisFilter = getGapAnalysisParameter();

    if (gapAnalysisFilter && gapAnalysisFilter.length > 0) {
      return true;
    }

    return false;
  };

  doesExternalFilterPass = (node): boolean => {
    let gapAnalysisFilter = getGapAnalysisParameter();
    let showFieldsWithData = getValueParameter() === "1";
    let result: boolean = true;
    let level = node.data.impactLevel;

    let type = node.data.impactTypeNames;
    let locations = node.data.locationNames;
    let businessAreas = node.data.businessAreaNames;
    let owner = node.data.ownerName;

    switch (gapAnalysisFilter) {
      case "type":
        result = this.rowHasValidTypeAndLevelData(type, level, showFieldsWithData);
        this.setGapAnalysisFilter("Type", showFieldsWithData);
        return result;
      case "location":
        result = this.rowHasValidLocationAndLevelData(locations, level, showFieldsWithData);
        this.setGapAnalysisFilter("Locations", showFieldsWithData);
        return result;
      case "businessArea":
        result = this.rowHasValidBusinessAreaAndLevelData(businessAreas, level, showFieldsWithData);
        this.setGapAnalysisFilter("Business Areas", showFieldsWithData);
        return result;
      case "owner":
        result = this.rowHasValidOwnerAndLevelData(owner, level, showFieldsWithData);
        this.setGapAnalysisFilter("Owner", showFieldsWithData);
        return result;
      case "all":
        if (showFieldsWithData) {
          result =
            this.rowHasValidTypeAndLevelData(type, level, showFieldsWithData) &&
            this.rowHasValidLocationAndLevelData(locations, level, showFieldsWithData) &&
            this.rowHasValidBusinessAreaAndLevelData(businessAreas, level, showFieldsWithData) &&
            this.rowHasValidOwnerAndLevelData(owner, level, showFieldsWithData);
        } else {
          result =
            this.rowHasValidTypeAndLevelData(type, level, showFieldsWithData) ||
            this.rowHasValidLocationAndLevelData(locations, level, showFieldsWithData) ||
            this.rowHasValidBusinessAreaAndLevelData(businessAreas, level, showFieldsWithData) ||
            this.rowHasValidOwnerAndLevelData(owner, level, showFieldsWithData);
        }

        this.setGapAnalysisFilter("All", showFieldsWithData);
        return result;
      default:
        return true;
    }
  };

  @action
  setGapAnalysisFilter = (filter: string, showFieldsWithData: boolean) => {
    this.gapAnalysisFilter = `${filter} & Level`;
    this.gapAnalysisCompleteData = showFieldsWithData;
  };

  levelIsValid = (level: number): boolean => {
    return level > 0 && level <= 10;
  };

  rowHasValidTypeAndLevelData = (types: string, level: number, showFieldsWithData: boolean): boolean => {
    if (showFieldsWithData) {
      return this.levelIsValid(level) && types !== null;
    } else {
      return this.levelIsValid(level) === false || types === null;
    }
  };

  rowHasValidLocationAndLevelData = (locations: string, level: number, showFieldsWithData: boolean): boolean => {
    if (showFieldsWithData) {
      return this.levelIsValid(level) && locations !== null;
    } else {
      return this.levelIsValid(level) === false || locations === null;
    }
  };

  rowHasValidBusinessAreaAndLevelData = (businessArea: string, level: number, showFieldsWithData: boolean): boolean => {
    if (showFieldsWithData) {
      return this.levelIsValid(level) && businessArea !== null;
    } else {
      return this.levelIsValid(level) === false || businessArea === null;
    }
  };

  rowHasValidOwnerAndLevelData = (owner: string, level: number, showFieldsWithData: boolean): boolean => {
    if (showFieldsWithData) {
      return this.levelIsValid(level) && owner !== null;
    } else {
      return this.levelIsValid(level) === false || owner === null;
    }
  };

  clearGapAnalysisFilter = () => {
    this.navigate(Pages.projects.impacts.listView.generateLink(this.organisationId, this.projectId));
    (window as any).location.reload();
  };

  @action
  setSearchText = (ev: React.FormEvent<HTMLInputElement>) => {
    this.searchText = ev.currentTarget.value;

    if (this.gridApi !== undefined) {
      this.gridApi.setGridOption("quickFilterText", this.searchText);
    }
  };

  assignStakeholderToImpacts = async (stakeholderIds: number[]) => {
    await this.projectStakeholderProvider.assignMultipleImpacts(
      this.organisationId,
      this.projectId,
      stakeholderIds,
      this.selectedImpacts
    );
    this.modalService.hide();
  };

  assignActionsToImpacts = async actionIds => {
    await this.impactProvider.addBulkActionsToImpacts(
      this.organisationId,
      this.projectId,
      this.selectedImpacts,
      actionIds
    );

    this.modalService.hide();
  };

  assignLocationsToImpacts = async locations => {
    await this.impactProvider.addBulkLocations(this.organisationId, this.projectId, this.selectedImpacts, locations);
    this.modalService.hide();
  };

  assignImpactGroupsToImpacts = async impactGroups => {
    await this.impactProvider.addBulkImpactGroups(
      this.organisationId,
      this.projectId,
      this.selectedImpacts,
      impactGroups
    );
    this.modalService.hide();
  };

  assignLabelsToImpacts = async labels => {
    await this.impactProvider.addBulkLabels(this.organisationId, this.projectId, this.selectedImpacts, labels);
    this.modalService.hide();
  };

  assignBusinessAreasToImpacts = async businessAreas => {
    await this.impactProvider.addBulkBusinessAreas(
      this.organisationId,
      this.projectId,
      this.selectedImpacts,
      businessAreas
    );
    this.modalService.hide();
  };
}
