import { UiSizes } from "@flightpath/coreui/dist/enums";
import { Button, ButtonIcon, ButtonIconShapes, ButtonTypes } from "@flightpath/coreui/dist/ui/Button";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import ModalContext from "../../../../../../../../core/modalZ/context/ModalContext";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";
import I18n from "../../../../../../../../core/localization/I18n";

export interface IL1ProcessSelectionViewProps {
  templateProcesses: any;
  handleNextStep: (e: any) => void;
  handlePreviousStep: () => void;
}

export const L1ProcessSelectionView: React.FC<ReactProps & IL1ProcessSelectionViewProps> = observer(props => {
  const { templateProcesses, handleNextStep, handlePreviousStep } = props;
  const [selectedL1Processes, setSelectedL1Processes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const allL1Processes = templateProcesses.map(e => e.id);

  useEffect(() => {
    if (allL1Processes.length === selectedL1Processes.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
    //eslint-disable-next-line
  }, [selectedL1Processes]);

  const handleSelectAllToggle = () => {
    setSelectedL1Processes(allL1Processes);
  };
  const handleDeselectAllToggle = () => {
    setSelectedL1Processes([]);
  };

  const onProcessSelected = (processId: number) => {
    if (selectedL1Processes.includes(processId)) {
      setSelectedL1Processes(selectedL1Processes.filter(e => e !== processId));
    } else {
      setSelectedL1Processes([...selectedL1Processes, processId]);
    }
  };

  return (
    <div className="col">
      <div className="row justify-content-center">
        <div className="col-7 p-4 justify-content-start">{I18n.t("phrases.selectL1Process")}</div>
        <div className="d-flex col-4 justify-content-end align-items-center">
          <Button
            className="mx-2"
            size={UiSizes.SM}
            type={ButtonTypes.OUTLINE_PRIMARY}
            shape={ButtonIconShapes.RECTANGLE}
            isDisabled={allSelected}
            onClick={handleSelectAllToggle}
            style={{ whiteSpace: "nowrap" }}
          >
            {I18n.t("phrases.selectAll")}
          </Button>
          <Button
            className="mx-2"
            size={UiSizes.SM}
            type={ButtonTypes.OUTLINE_PRIMARY}
            isDisabled={selectedL1Processes.length === 0}
            shape={ButtonIconShapes.RECTANGLE}
            onClick={handleDeselectAllToggle}
            style={{ whiteSpace: "nowrap" }}
          >
            {I18n.t("phrases.deselectAll")}
          </Button>
        </div>
        <div className=" d-flex align-items-center px-0 justify-content-end">
          <ButtonIcon
            size={UiSizes.SM}
            onClick={ModalContext.hide}
            type={ButtonTypes.OUTLINE_PRIMARY}
            shape={ButtonIconShapes.RECTANGLE}
            symbol={IconSymbols.Close}
          />
        </div>
      </div>
      <div>
        <div className="row p-4 l1-process-list">
          {templateProcesses
            .sort((a, b) => a.order - b.order)
            .map(e => {
              return (
                e.depth === 0 && (
                  <Button
                    className="m-1"
                    type={selectedL1Processes.includes(e.id) ? ButtonTypes.SECONDARY : ButtonTypes.OUTLINE_SECONDARY}
                    onClick={() => onProcessSelected(e.id)}
                  >
                    {e.name}
                  </Button>
                )
              );
            })}
        </div>
        <div className="row p-4 justify-content-between">
          <Button type={ButtonTypes.OUTLINE_PRIMARY} onClick={() => handlePreviousStep()}>
            {I18n.t("phrases.back")}
          </Button>
          <Button
            type={ButtonTypes.PRIMARY}
            isDisabled={selectedL1Processes.length === 0}
            onClick={() => selectedL1Processes.length > 0 && handleNextStep(selectedL1Processes)}
          >
            {I18n.t("phrases.next")}
          </Button>
        </div>
      </div>
    </div>
  );
});
