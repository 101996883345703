import _ from "lodash";
import { AutocompleteOption } from "../../../../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FORM_COL } from "../../../../../../../../constants";
import { IMultiSelectorModel } from "../../../../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { generateFormFieldsFromJson } from "../../../../../../../../core/forms/helpers/FormFieldMappers";
import { filterOutExistingProcessAppVals } from "../../../../../../../../core/grids/GridHelpers";
import I18n from "../../../../../../../../core/localization/I18n";
import { ActivityProcessesApi } from "../../../../../../../../services/api/v2/activityProcesses/ActivityProcesses.api";
import { ProjectProcessesApi } from "../../../../../../../../services/api/v2/projectProcesses/ProjectProcesses.api";

export const getProjectProcessRolesSidebarFields = (
  organisationId: number,
  projectId: number,
  projectProcessProvider: ProjectProcessesApi,
  processProvider: ActivityProcessesApi,
  process: FP.Entities.IProjectProcess,
  currentProcessRoles: FP.Entities.IProcessRole[]
) => {
  const removeProcessRole = async (processRole: FP.Entities.IProcessRole) => {
    let res = await projectProcessProvider.removeProcessRole(organisationId, projectId, process.id, processRole.id);
    if (!res) return;
  };

  const addProcessRole = async (processRole: FP.Entities.IProcessRole) => {
    let res = await projectProcessProvider.addProcessRole(
      organisationId,
      projectId,
      process.id,
      processRole.id,
      processRole.name
    );
    if (!res) return;
  };

  const addNewProcessRole = async (processRoleName: string) => {
    const processRole: FP.Entities.IProcessRole = {
      id: 0,
      name: processRoleName,
      slug: _.kebabCase(processRoleName),
      organisationId: organisationId
    };

    await addProcessRole(processRole);
  };

  const processRoles: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "processRoles",
    placeholder: I18n.t("placeholders.searchOrCreateProcessRoles"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: any) => e.text || e.name} />
    ),
    onItemSelected: e => addProcessRole(e),
    onFocus: async function () {
      const self: IMultiSelectorModel = this;

      const currentProcessRoles = await projectProcessProvider.getProcessRoles(organisationId, projectId, process.id);
      const orgProcessRoles = await processProvider.getRolesByOrgId(organisationId, projectId, projectId);

      if (currentProcessRoles?.payload && orgProcessRoles?.payload) {
        const currentVals = self.extractValue();
        const sortedProcessRoles = _.orderBy(orgProcessRoles.payload, [processRole => processRole.name.toLowerCase()]);
        self.setOptions(
          filterOutExistingProcessAppVals(currentVals, sortedProcessRoles).map(e => ({ ...e, text: e.name }))
        );
      }
    },

    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: currentProcessRoles.map(e => {
      return { ...e, text: e.name };
    }),
    isHidden: false,
    valueLabelFn: e => e?.text || e.name,
    saveOnEnterFn: e => addNewProcessRole(e.target.value),
    removeItemFn: (e, i) => removeProcessRole(e),
    isTagSelector: false,
    allowFreeText: true,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: I18n.t("forms.processRolesResultsNotFound"),
    searchResultHint: I18n.t("forms.processRolesSearchResultHint")
  };

  const fields = [];

  fields.push(processRoles);
  const models = generateFormFieldsFromJson(fields);
  return models;
};
