import { observer } from "mobx-react";
import React from "react";
import { PositionedSpinner } from "../../../../../../../components/ui/PositionedSpinner";
import { SingleForm } from "../../../../../../change/forms/singleFormModel/SingleForm_view";
import { ImpactMilestonesSidebarModel } from "./ImpactMilestonesSidebar_model";
interface ImpactMilestonesSidebarProps {
  model: ImpactMilestonesSidebarModel;
}

export const ImpactMilestonesSidebar: React.FC<ImpactMilestonesSidebarProps> = observer(({ model }) => {
  if (model.isLoading || !model.formModel) return <PositionedSpinner />;

  return (
    <div className="container-fluid pt-3">
      <SingleForm model={model.formModel} />
    </div>
  );
});
