import { ColDef } from "ag-grid-community";
import {
  colEndDate,
  colName,
  colOwner,
  colProgrammeName,
  colProgressStatus,
  colProjectName,
  colRefNumber,
  colStartDate
} from "../../../../../core/grids/CommonGridColumns";
import { ActionField, EntityTypes } from "../../../../../enums";
import Pages from "../../../../../routes/InsightRoutes";
import { colImpactLevel } from "../../../projects/ImpactsView/ImpactGridView/ImpactGridView_baseColumns";

export interface IGetImpactGridViewColumns {
  progressStatuses: FP.Generic.IKeyLabel[];
  projectTeamMembers: FP.Entities.IUser[];
  canEdit: boolean;
  organisationId: number;
  projectId: number;
}

export const GetImpactGridViewColumns = (modelProps: IGetImpactGridViewColumns): ColDef[] => {
  let result: ColDef[] = [];

  result.push(
    colRefNumber(modelProps.organisationId, modelProps.projectId, EntityTypes.IMPACTS, { pinned: undefined })
  );
  result.push(colName(modelProps.canEdit, null, ActionField.name, "", { width: 200, pinned: undefined }));
  result.push(colImpactLevel(modelProps.canEdit));

  result.push(
    colProjectName(
      modelProps.canEdit,
      null,
      ActionField.projectName,
      Pages.baseRoute.generateLink(modelProps.organisationId),
      { width: 200, pinned: undefined }
    )
  );
  result.push(
    colProgrammeName(
      modelProps.canEdit,
      null,
      ActionField.programmeName,
      Pages.baseRoute.generateLink(modelProps.organisationId),
      { width: 200, pinned: undefined }
    )
  );

  result.push(colOwner(modelProps.canEdit, modelProps.projectTeamMembers, null, ActionField.owner, {}));
  result.push(colStartDate(modelProps.canEdit, null, ActionField.startDate, {}));
  result.push(colEndDate(modelProps.canEdit, null, ActionField.endDate, {}));
  result.push(colProgressStatus(modelProps.canEdit, modelProps.progressStatuses, null, ActionField.progressStatus, {}));

  return result;
};
