import React from "react";
import { Panel } from "../../../../components/ui/Panel";
import { ChartUsage, GapAnalysisChart } from "../../../../components/widgets/gapAnalysisChart/gapAnalysisChart";
import I18n from "../../../../core/localization/I18n";
import { ReportingPie } from "../Actions/ActionReportingView/TopActionsReportingBar/ReportingPie";
import { useNavigate } from "react-router-dom";
import Pages from "../../../../routes/InsightRoutes";

interface TopImpactsReportingBarProps {
  ragData: any[];
  impactCount: number;
  dataConfidence: number;
  organisationId: number;
  projectId: number;
}

export const TopImpactsReportingBar: React.FC<TopImpactsReportingBarProps> = ({
  ragData,
  impactCount,
  dataConfidence,
  organisationId,
  projectId
}) => {
  const ragColourArray = ["#3D00C1", "#834FF1", "#BFA1FF", "#E5E5E5"];

  const navigate = useNavigate();
  const impactTypeOnClickFunction = (dataObject: any) => {
    let level = dataObject.id.charAt(0).toUpperCase() + dataObject.id.slice(1);

    let url = `${Pages.projects.impacts.listView.generateLink(organisationId, projectId)}/?level=${level}`;
    navigate(url);
  };

  return (
    <div className="row pt-4 justify-content-between">
      <div className="col-4">
        {ragData && impactCount && (
          <ReportingPie
            data={ragData}
            metric={impactCount.toString()}
            colourArray={ragColourArray}
            title={I18n.t("visualisations.impactsLevel")}
            onClick={impactTypeOnClickFunction}
          />
        )}
      </div>
      <div className="col-4">
        <Panel.Panel
          hasBorder
          hasBorderRadius
          background={Panel.PanelBackgrounds.BG_LIGHT}
          className="p-3"
          style={{ height: 200 }}
        >
          <GapAnalysisChart dataConfidence={dataConfidence} heading="Gap Analysis" chartUsage={ChartUsage.Chart} />
        </Panel.Panel>
      </div>
      <div className="col-4"></div>
    </div>
  );
};
